import React, { Component } from 'react'
import client from '../../modules/client'
import { connect } from 'react-redux'

const SUCCESS = 1;
const ERROR = 2;
const IDLE = 0;
class PaymentRequest extends Component {
  state = {
    amount: '',
    requestEnabled: false,
    requestStatus: IDLE,
    errorMessage: ''
  }

  handleInput = e => {
    if (isNaN(parseInt(e.target.value))) {
      this.setState({ amount: '0', requestEnabled: false })
    } else {
      let tempAmount = parseInt(e.target.value.split(",").join(""))
      let amount = 0;
      if (tempAmount > this.props.confirmedIncome) {
        amount = Math.floor(this.props.confirmedIncome / 1000) * 1000
      } else {
        amount = tempAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      if (tempAmount > 100000) {
        this.setState({
          amount,
          requestEnabled: true
        })
      } else {
        this.setState({ amount, requestEnabled: false })
      }
    }
  }

  removeInputRemainder = e => {
    const tempAmount = Math.floor(parseInt(this.state.amount.split(",").join("")) / 1000) * 1000
    const amount = tempAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    this.setState({
      amount
    })
  }

  handleSubmit = e => {
    const amount = parseInt(this.state.amount.split(",").join(""))
    client({
      method: 'post',
      url: '/payment_histories',
      data: {
        amount: amount
      },
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      if (res.status === 201) {
        this.setState({
          requestStatus: SUCCESS
        })
      }
    }).catch(err => {
      this.setState({
        requestStatus: ERROR,
        errorMessage: err.response.data.error_message
      })
    })
  }

  handleCancel = e => {
    this.props.closePopup(e);
  }

  retryRequest = e => {
    this.setState({
      requestStatus: IDLE,
      amount: '',
      requestEnabled: false,
      errorMessage: ''
    })
  }

  goToPaymentHistory = e => {
    this.props.goToPaymentHistory(e)
  }

  render() {
    if (this.state.requestStatus === IDLE) {
      const submitBtn = this.state.requestEnabled ? <div className="submit" onClick={this.handleSubmit}>신청</div> : <div className="submit disabled">신청</div>

      return (
        <div className="payment-request-wrapper">
          <div className="payment-request-background"></div>
          <div className="payment-request-input-wrapper">
            <div className="payment-request-container">
              <div className="title">출금 신청하기</div>
              <div className="payment-request-input">
                <div className="input-wrapper">
                  <div className="amount-wrapper">
                    <input type="text" value={this.state.amount} onChange={this.handleInput} onBlur={this.removeInputRemainder} placeholder="출금 신청 금액을 1000원 단위로 입력해주세요." />
                    <div className="won">원</div>
                  </div>
                  <div className="information">최대 출금 가능액: {this.props.confirmedIncome}원</div>
                </div>
                <div className="btns-wrapper">
                  {submitBtn}
                  <div className="cancel" onClick={this.handleCancel}>취소</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (this.state.requestStatus === SUCCESS) {
      return (
        <div className="payment-request-wrapper">
          <div className="payment-request-background"></div>
          <div className="payment-request-content">
            <div className="payment-request-container">
              <div className="message">출금 신청이 완료되었습니다.</div>
              <div className="btns-wrapper">
                <div className="submit" onClick={this.goToPaymentHistory}>출금 내역 보기</div>
                <div className="cancel" onClick={this.handleCancel}>닫기</div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="payment-request-wrapper">
          <div className="payment-request-background"></div>
          <div className="payment-request-content">
            <div className="payment-request-container">
              <div className="message">{this.state.errorMessage}</div>
              <div className="btns-wrapper">
                <div className="submit" onClick={this.retryRequest}>다시 신청하기</div>
                <div className="cancel" onClick={this.handleCancel}>닫기</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(PaymentRequest)