import React, { Component } from 'react';
import client from '../../modules/client';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'
import loadingIcon from '../../assets/images/Ripple-1s-200px.svg'

class NewChannel extends Component {
  state = {
    name: '',
    description: '',
    thumbnailFile: '',
    thumbnailFilePreviewUrl: '',
    bgColor: '',
    isScheduled: true,
    scheduleDate: new Date(),
    sounds: [],
    divisions: [],
    divisionId: '',
    tags: [],
    tagId: '',
    creators: [],
    isNarrationIncluded: false,
    writerId: '',
    narratorId: '',
    producerId: '',
    isFinished: false,
    isClass: false,
    classDuration: null,
    uploading: false
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/divisions',
    })
      .then(res => {
        const divisions = res.data.data;
        this.setState({ divisions })
      })

    client({
      method: 'get',
      url: '/tags'
    }).then(res => {
      const tags = res.data.data;
      this.setState({ tags })
    })

    client({
      method: 'get',
      url: '/creators'
    }).then(res => {
      const creators = res.data.data;
      this.setState({ creators })
    })
  }

  handleNameInput = e => {
    this.setState({ name: e.target.value })
  }

  handleDescriptionInput = e => {
    this.setState({ description: e.target.value })
  }

  handleDivisionSelect = e => {
    this.setState({ divisionId: e.target.value })
  }

  handleTagSelect = e => {
    this.setState({ tagId: e.target.value })
  }

  handleThumbnailFileChange = e => {
    e.preventDefault();

    let file = e.target.files[0];

    this.setState({
      thumbnailFile: file,
      thumbnailFilePreviewUrl: URL.createObjectURL(file)
    })
  }

  handleClassDurationInput = e => {
    this.setState({ classDuration: e.target.value })
  }

  handleIsNarrationIncludedChange = e => {
    this.setState({ isNarrationIncluded: e.target.checked })
  }

  handleIsFinishedChange = e => {
    this.setState({ isFinished: e.target.checked })
  }

  handleIsClassChanged = e => {
    if (!e.target.checked) {
      this.setState({ isClass: e.target.checked })
      this.setState({ classDuration: null })
    } else {
      this.setState({ isClass: e.target.checked })
    }
  }

  handleWriterChange = e => {
    this.setState({ writerId: e.target.value })
  }

  handleNarratorChange = e => {
    this.setState({ narratorId: e.target.value })
  }

  handleProducerChange = e => {
    this.setState({ producerId: e.target.value })
  }

  handleScheduleDateChange = e => {
    this.setState({ scheduleDate: e })
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.thumbnailFile === '') {
      alert('채널 썸네일을 업로드해주세요.')
    } else {
      let channelFormData = new FormData();
      channelFormData.append('name', this.state.name);
      channelFormData.append('description', this.state.description);
      channelFormData.append('division_id', this.state.divisionId);
      channelFormData.append('tag_id', this.state.tagId);
      channelFormData.append('image', this.state.thumbnailFile, this.state.thumbnailFile.name);
      channelFormData.append('is_narration_included', this.state.isNarrationIncluded);
      channelFormData.append("is_scheduled", this.state.isScheduled)
      channelFormData.append("is_finished", this.state.isFinished)
      channelFormData.append("is_class", this.state.isClass)
      channelFormData.append("class_duration", this.state.classDuration)
      if (this.props.user.isSuperUser) {
        if (this.state.isNarrationIncluded) {
          channelFormData.append('writer_id', this.state.writerId);
          channelFormData.append('narrator_id', this.state.narratorId);
          channelFormData.append('producer_id', this.state.producerId);
        } else {
          channelFormData.append('producer_id', this.state.producerId);
        }
      }
      const scheduleDate = this.state.scheduleDate
      const year = scheduleDate.getFullYear()
      const month = scheduleDate.getMonth() + 1
      const date = scheduleDate.getDate()
      const hour = scheduleDate.getHours()
      const minute = scheduleDate.getMinutes()
      const formattedScheduleDate = year + "-" + month + "-" + date + " " + hour + ":" + minute + " UTC+09"
      channelFormData.append("schedule_date", formattedScheduleDate)
      channelFormData.append('bg_color', this.state.bgColor);
      this.setState({ uploading: true }, () => {
        client({
          method: 'post',
          url: '/channels',
          data: channelFormData,
          headers: {
            'Authorization': 'Bearer ' + this.props.user.token,
            'content-type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.status === 201) {
            this.setState({ uploading: false })
            this.props.history.push('/channels')
          }
        }).catch(err => {
          // TODO: handle error
          this.setState({ uploading: false })
          console.log("channel err: ", err)
        })
      })
    }
  }

  handleCancel = e => {
    this.props.history.push('/channels')
  }

  render() {
    if (this.state.uploading) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }
    const { thumbnailFilePreviewUrl } = this.state;
    const thumbnailPreview = thumbnailFilePreviewUrl ? (<img src={thumbnailFilePreviewUrl} className="preview-img" alt="preview" />) : (<div className="preview-text">채널 썸네일을 선택해주세요</div>)
    const divisionOptions = this.state.divisions.map(division => {
      return <option value={division.id} key={division.id}>{division.name}</option>
    })
    const tagOptions = this.state.tags.map(tag => {
      return <option value={tag.id} key={tag.id}>{tag.name}</option>
    })
    const creatorOptions = this.state.creators.map(creator => {
      return <option value={creator.id} key={creator.id}>{creator.name}</option>
    })
    const creatorSelect = this.props.user.isSuperUser ? (this.state.isNarrationIncluded ? (
      <div className="channel__creator-select-wrapper">
        <div className="channel__input-wrapper">
          <div className="channel__input-label">
            <label htmlFor="channel-narrator">내레이터</label>
          </div>
          <div className="channel__input">
            <select className="channel__input-narrator" id="channel-narrator" onChange={this.handleNarratorChange}>
              <option value="" disabled selected>내레이터를 골라주세요</option>
              {creatorOptions}
            </select>
          </div>
        </div>
        <div className="channel__input-wrapper">
          <div className="channel__input-label">
            <label htmlFor="channel-writer">스크립트 작가</label>
          </div>
          <div className="channel__input">
            <select className="channel__input-writer" id="channel-writer" onChange={this.handleWriterChange}>
              <option value="" disabled selected>작가를 골라주세요</option>
              {creatorOptions}
            </select>
          </div>
        </div>
        <div className="channel__input-wrapper">
          <div className="channel__input-label">
            <label htmlFor="channel-producer">프로듀서</label>
          </div>
          <div className="channel__input">
            <select className="channel__input-producer" id="channel-producer" onChange={this.handleProducerChange}>
              <option value="" disabled selected>프로듀서를 골라주세요</option>
              {creatorOptions}
            </select>
          </div>
        </div>
      </div>
    )
      : (
        <div className="channel__creator-select-wrapper">
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="channel-producer">프로듀서</label>
            </div>
            <div className="channel__input">
              <select className="channel__input-producer" id="channel-producer" onChange={this.handleProducerChange}>
                <option value="" disabled selected>프로듀서를 골라주세요</option>
                {creatorOptions}
              </select>
            </div>
          </div>
        </div>
      )
    ) : (null
      )
    const scheduleDatePicker = this.state.isScheduled ? (
      <div className="channel__input-wrapper">
        <div className="channel__input-label schedule">
          <label>예약 날짜</label>
        </div>
        <div className="channel__input">
          <div className="channel__schedule-date-wrapper">
            <DateTimePicker
              onChange={this.handleScheduleDateChange}
              value={this.state.scheduleDate}
            />
          </div>
        </div>
      </div>
    ) : null
    const isClassCheckbox = this.props.user.isSuperUser ? (
      <div className="channel__input-wrapper">
        <div className="channel__input-label">
          <label htmlFor="channel-is-class">클래스 채널</label>
        </div>
        <div className="channel__input">
          <input type="checkbox" id="channel-is-class" checked={this.state.isClass} onChange={this.handleIsClassChanged} />
        </div>
      </div>
    ) : null
    const classDurationInput = this.props.user.isSuperUser && this.state.isClass ? (
      <div className="channel__input-wrapper">
        <div className="channel__input-label">
          <label htmlFor="channel-class-duration">클래스 기간(일 수)</label>
        </div>
        <div className="channel__input">
          <input type="number" id="channel-class-duration" onChange={this.handleClassDurationInput} />
        </div>
      </div>
    ) : null

    return (
      <div className="channel">
        <h3>새 채널 만들기</h3>
        <div className="channel__form">
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="name">채널 이름</label>
            </div>
            <div className="channel__input">
              <textarea type="text" id="name" placeholder="채널 이름을 입력해주세요." onChange={this.handleNameInput} />
            </div>
          </div>
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="description">채널 소개</label>
            </div>
            <div className="channel__input">
              <textarea type="text" id="description" placeholder="채널을 소개해주세요." onChange={this.handleDescriptionInput} />
            </div>
          </div>
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="division">카테고리</label>
            </div>
            <div className="channel__input">
              <select className="channel__input-division" id="division" onChange={this.handleDivisionSelect}>
                <option value="">카테고리를 선택해주세요</option>
                {divisionOptions}
              </select>
            </div>
          </div>
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="tag">섹션</label>
            </div>
            <div className="channel__input">
              <select className="channel__input-tag" id="tag" onChange={this.handleTagSelect}>
                <option value="">섹션을 선택해주세요</option>
                {tagOptions}
              </select>
            </div>
          </div>
          <div className="channel__input-wrapper channel-image">
            <div className="channel__input-label">
              <label htmlFor="channel_thumbnail">썸네일</label>
            </div>
            <div className="channel__input">
              <input type="file" id="channel_thumbnail" accept="image/*" onChange={this.handleThumbnailFileChange} />
            </div>
            <div className="channel__image-preview">
              {thumbnailPreview}
            </div>
          </div>
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="channel-is-music-only">내레이션 여부</label>
            </div>
            <div className="channel__input">
              <input type="checkbox" id="channel-is-music-only" checked={this.state.isNarrationIncluded} onChange={this.handleIsNarrationIncludedChange} />
            </div>
          </div>
          {creatorSelect}
          {scheduleDatePicker}
          <div className="channel__input-wrapper">
            <div className="channel__input-label">
              <label htmlFor="channel-is-finished">채널 완결 여부</label>
            </div>
            <div className="channel__input">
              <input type="checkbox" id="channel-is-finished" checked={this.state.isFinished} onChange={this.handleIsFinishedChange} />
            </div>
          </div>
          {isClassCheckbox}
          {classDurationInput}
        </div>
        <div className="channel__btns-wrapper">
          <button className="channel__submit-btn" onClick={this.handleSubmit}>채널 저장</button>
          <button className="channel__cancel-btn" onClick={this.handleCancel}>취소</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(NewChannel))