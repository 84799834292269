import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ChannelList from '../../components/channels/ChannelList'
import client from '../../modules/client';

const LIVE_CHANNELS = 0
const SCHEDULED_CHANNELS = 1

class Channels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: LIVE_CHANNELS,
      channels: []
    }
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/channels',
      params: {
        scheduled: false
      },
      headers: { 'Authorization': 'Bearer ' + this.props.user.token }
    }).then(res => {
      const channels = res.data.data;
      this.setState({ channels, selectedTab: LIVE_CHANNELS })
    })
  }

  handleLiveChannelsTabClick = e => {
    if (this.state.selectedTab === SCHEDULED_CHANNELS) {
      client({
        method: 'get',
        url: '/channels',
        params: {
          scheduled: false
        },
        headers: { 'Authorization': 'Bearer ' + this.props.user.token }
      }).then(res => {
        const channels = res.data.data;
        this.setState({ channels, selectedTab: LIVE_CHANNELS })
      })
    }
  }

  handleScheduledChannelsTabClick = e => {
    if (this.state.selectedTab === LIVE_CHANNELS) {
      client({
        method: 'get',
        url: '/channels',
        params: {
          scheduled: true
        },
        headers: { 'Authorization': 'Bearer ' + this.props.user.token }
      }).then(res => {
        const channels = res.data.data;
        this.setState({ channels, selectedTab: SCHEDULED_CHANNELS })
      })
    }
  }

  render() {
    const liveChannelClass = this.state.selectedTab === LIVE_CHANNELS ? "channels__navbar-tab selected" : "channels__navbar-tab"
    const scheduleChannelClass = this.state.selectedTab === SCHEDULED_CHANNELS ? "channels__navbar-tab selected" : "channels__navbar-tab"

    return (
      <div className="channels">
        <div className="navbar-channels">
          <ul className="channels__navbar-list">
            <li className={liveChannelClass}>
              <div className="btn__channels-navbar" onClick={this.handleLiveChannelsTabClick}>
                공개된 채널
              </div>
            </li>
            <li className={scheduleChannelClass}>
              <div className="btn__channels-navbar" onClick={this.handleScheduledChannelsTabClick}>
                예약된 채널
              </div>
            </li>
          </ul>
          <Link to={"/channels/new"} className="btn__new-channel">새 채널 만들기</Link>
        </div>
        <ChannelList channels={this.state.channels} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(Channels)