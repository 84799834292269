import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import client from '../../modules/client';
import loadingIcon from '../../assets/images/Ripple-1s-200px.svg'
import xIcon from '../../assets/images/ic_x.png'

const ESSAY_DIVISION_ID = 2

class NewSound extends Component {
  state = {
    name: '',
    description: '',
    durationMin: '',
    durationSec: '',
    soundFile: '',
    image: '',
    imagePreviewUrl: '',
    script: '',
    isNarrationIncluded: false,
    priority: '',
    isPremium: false,
    writerId: '',
    narratorId: '',
    producerId: '',
    isScheduled: false,
    scheduleDate: new Date(),
    creators: [],
    uploading: false,
    hashTagList: [],
    hashTags: [],
    easyPlayTagList: [],
    easyPlayTags: [],
    divisionId: ''
  }

  async componentDidMount() {
    try {
      const channelId = this.props.location.pathname.split("/")[2]
      const channelRes = await client({
        method: 'get',
        url: '/channels/' + channelId,
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })
      const divisionId = channelRes.data.data.division_id
      const creatorRes = await client({
        method: 'get',
        url: '/creators'
      })
      const creators = creatorRes.data.data;
      const hashTagsRes = await client({
        method: 'get',
        url: '/hash_tags',
        params: {
          division_id: divisionId
        }
      })
      const hashTagList = hashTagsRes.data.data;
      const easyPlayTagRes = await client({
        method: 'get',
        url: '/easy_play_tags'
      })
      const easyPlayTagList = easyPlayTagRes.data.data;

      this.setState({
        divisionId,
        creators,
        hashTagList,
        easyPlayTagList
      })
    } catch (err) {
      console.log(err)
    }
  }

  handleNameInput = e => {
    this.setState({ name: e.target.value })
  }

  handleDescriptionInput = e => {
    this.setState({ description: e.target.value })
  }

  handleHashTagSelect = e => {
    const [id, name] = e.value.split("%")
    const hashTag = { id: parseInt(id), name }
    let hashTags = this.state.hashTags
    hashTags.push(hashTag)
    let hashTagList = this.state.hashTagList.filter(tag => tag.id !== hashTag.id)
    this.setState({
      hashTags,
      hashTagList
    })
  }

  handleHashTagDeselect = e => {
    const [id, name] = e.target.dataset.hashtag.split("%")
    const hashTag = { id: parseInt(id), name }
    let hashTags = this.state.hashTags.filter(tag => tag.id !== hashTag.id)
    let hashTagList = this.state.hashTagList
    hashTagList.push(hashTag)
    hashTagList = hashTagList.sort((a, b) => a.id - b.id)
    this.setState({
      hashTags,
      hashTagList
    })
  }

  handleEasyPlayTagSelect = e => {
    const [id, name] = e.value.split("%")
    const easyPlayTag = { id: parseInt(id), name }
    let easyPlayTags = this.state.easyPlayTags
    if (easyPlayTags.length < 3) {
      easyPlayTags.push(easyPlayTag)
      let easyPlayTagList = this.state.easyPlayTagList.filter(tag => tag.id !== easyPlayTag.id)
      this.setState({
        easyPlayTags,
        easyPlayTagList
      })
    }
  }

  handleEasyPlayTagDeselect = e => {
    const [id, name] = e.target.dataset.easyplaytag.split("%")
    const easyPlayTag = { id: parseInt(id), name }
    let easyPlayTags = this.state.easyPlayTags.filter(tag => tag.id !== easyPlayTag.id)
    let easyPlayTagList = this.state.easyPlayTagList
    easyPlayTagList.push(easyPlayTag)
    easyPlayTagList = easyPlayTagList.sort((a, b) => a.id - b.id)
    this.setState({
      easyPlayTags,
      easyPlayTagList
    })
  }

  handlePriorityInput = e => {
    this.setState({ priority: e.target.value })
  }

  handleWriterChange = e => {
    this.setState({ writerId: e.target.value })
  }

  handleNarratorChange = e => {
    this.setState({ narratorId: e.target.value })
  }

  handleProducerChange = e => {
    this.setState({ producerId: e.target.value })
  }

  handleIsPremiumChange = e => {
    this.setState({ isPremium: e.target.checked })
  }

  handleIsNarrationIncludedChange = e => {
    this.setState({ isNarrationIncluded: e.target.checked })
  }

  handleFileChange = e => {
    const soundFile = e.target.files[0];
    const soundFilePreviewUrl = URL.createObjectURL(e.target.files[0]);
    const sound = document.getElementById('audio__preview-full')
    let durationMin = null;
    let durationSec = null;
    sound.src = URL.createObjectURL(e.target.files[0]);
    sound.addEventListener('loadedmetadata', () => {
      const duration = Math.floor(sound.duration)
      durationMin = Math.round(duration / 60)
      durationSec = duration
      this.setState({
        soundFile,
        soundFilePreviewUrl,
        durationMin,
        durationSec
      })
    })
  }

  handleImageChange = e => {
    const image = e.target.files[0];
    const imagePreviewUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      image,
      imagePreviewUrl
    })
  }

  handleScriptFileChange = e => {
    this.setState({ script: e.target.files[0] })
  }

  handleIsScheduleCheck = e => {
    this.setState({ isScheduled: e.target.checked })
  }

  handleScheduleDateChange = e => {
    this.setState({ scheduleDate: e })
  }

  handleSubmit = e => {
    const channelId = this.props.location.pathname.split("/")[2]
    const hashTags = this.state.hashTags.map(tag => tag.id).join(",")
    const easyPlayTags = this.state.easyPlayTags.map(tag => tag.id).join(",")
    if (this.state.soundFile === '') {
      alert('오디오 파일을 업로드 해주세요.')
    } else if (this.state.priority === '') {
      alert('오디오의 채널 내 순서를 입력해 주세요.')
    } else if (this.state.image === '') {
      alert('오디오 커버 이미지를 업로드 해주세요.')
    } else {
      let soundFormData = new FormData();
      soundFormData.append('name', this.state.name);
      soundFormData.append('description', this.state.description);
      soundFormData.append('hash_tags', hashTags);
      soundFormData.append('easy_play_tags', easyPlayTags);
      soundFormData.append('duration', this.state.durationMin);
      soundFormData.append('duration_sec', this.state.durationSec);
      soundFormData.append('channel_id', channelId);
      soundFormData.append('priority', this.state.priority);
      soundFormData.append('is_premium', this.state.isPremium);
      soundFormData.append('file', this.state.soundFile);
      soundFormData.append('image', this.state.image, this.state.image.name);
      soundFormData.append('is_narration_included', this.state.isNarrationIncluded);
      soundFormData.append('is_scheduled', this.state.isScheduled);
      if (this.state.isNarrationIncluded) {
        if (this.state.script !== '') {
          soundFormData.append('script', this.state.script, this.state.script.name);
        }
        if (this.props.user.isSuperUser) {
          soundFormData.append('writer_id', this.state.writerId);
          soundFormData.append('narrator_id', this.state.narratorId);
          soundFormData.append('producer_id', this.state.producerId);
        }
      } else {
        if (this.props.user.isSuperUser) {
          soundFormData.append('producer_id', this.state.producerId);
        }
      }
      soundFormData.append("is_scheduled", this.state.isScheduled)
      if (this.state.isScheduled) {
        const scheduleDate = this.state.scheduleDate
        const year = scheduleDate.getFullYear()
        const month = scheduleDate.getMonth() + 1
        const date = scheduleDate.getDate()
        const hour = scheduleDate.getHours()
        const minute = scheduleDate.getMinutes()
        const formattedScheduleDate = year + "-" + month + "-" + date + " " + hour + ":" + minute + " UTC+09"
        soundFormData.append("schedule_date", formattedScheduleDate)
      }
      this.setState({ uploading: true }, () => {
        client({
          method: 'post',
          url: '/sounds',
          data: soundFormData,
          headers: {
            'Authorization': 'Bearer ' + this.props.user.token,
            'content-type': 'multipart/form-data'
          }
        }).then(res => {
          this.setState({ uploading: false })
          this.props.history.push('/channels/' + channelId + '/show')
        }).catch(err => {
          // TODO: handle error
          this.setState({ uploading: false })
          console.log("sound err: ", err)
        })
      })
    }
  }

  handleCancel = e => {
    const channelId = this.props.location.pathname.split("/")[2]
    this.props.history.push('/channels/' + channelId + '/show')
  }

  render() {
    if (this.state.uploading) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }
    const hashTagOptions = this.state.hashTagList.map(hashTag => {
      return { value: hashTag.id + "%" + hashTag.name, label: hashTag.name }
    })
    const easyPlayTagOptions = this.state.easyPlayTagList.map(easyPlayTag => {
      return { value: easyPlayTag.id + "%" + easyPlayTag.name, label: easyPlayTag.name }
    })
    const selectedHashTags = this.state.hashTags.map(hashTag => {
      return (<div className="tag" key={hashTag.id} data-hashtag={hashTag.id + "%" + hashTag.name} onClick={this.handleHashTagDeselect}>
        <span>{hashTag.name}</span>
        <button className="tag-deselect"><img src={xIcon} alt="x" /></button>
      </div>)
    })
    const selectedEasyPlayTags = this.state.easyPlayTags.map(easyPlayTag => {
      return (<div className="tag" key={easyPlayTag.id} data-easyplaytag={easyPlayTag.id + "%" + easyPlayTag.name} onClick={this.handleEasyPlayTagDeselect}>
        <span>{easyPlayTag.name}</span>
        <button className="tag-deselect"><img src={xIcon} alt="x" /></button>
      </div>)
    })
    const hashTagDropdown = this.state.divisionId !== ESSAY_DIVISION_ID ? (<div className="sound__item-tag-select-wrapper">
      <div className="sound__item-input-wrapper">
        <div className="sound__item-input-label">
          <label htmlFor="sound-hash-tags">해쉬태그</label>
        </div>
        <div className="sound__item-input">
          <Dropdown options={hashTagOptions} onChange={this.handleHashTagSelect} placeholder="해쉬태그를 선택해주세요" />
        </div>
        <div className="sound__tags-selected">
          {selectedHashTags}
        </div>
      </div>
    </div>) : null
    const soundImagePreview = this.state.imagePreviewUrl ? (<img src={this.state.imagePreviewUrl} className="sound-preview-img" alt="preview" />) : (<div className="sound-preview-text">곡 커버 이미지를 선택해주세요</div>)
    const creatorOptions = this.state.creators.map(creator => {
      return <option value={creator.id} key={creator.id}>{creator.name}</option>
    })
    const creatorSelect = this.props.user.isSuperUser ? (this.state.isNarrationIncluded ? (
      <div className="sound__item-creator-select-wrapper">
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-narrator">내레이터</label>
          </div>
          <div className="sound__item-input">
            <select className="sound__item-input-narrator" id="sound-narrator" onChange={this.handleNarratorChange}>
              <option value="" disabled selected>내레이터를 골라주세요</option>
              {creatorOptions}
            </select>
          </div>
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-writer">스크립트 작가</label>
          </div>
          <div className="sound__item-input">
            <select className="sound__item-input-narrator" id="sound-writer" onChange={this.handleWriterChange}>
              <option value="" disabled selected>작가를 골라주세요</option>
              {creatorOptions}
            </select>
          </div>
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-script">스크립트 파일</label>
          </div>
          <div className="sound__item-input">
            <input type="file" id="sound-script" accept="application/pdf" onChange={this.handleScriptFileChange} />
          </div>
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-producer">프로듀서</label>
          </div>
          <div className="sound__item-input">
            <select className="sound__item-input-narrator" id="sound-producer" onChange={this.handleProducerChange}>
              <option value="" disabled selected>프로듀서를 골라주세요</option>
              {creatorOptions}
            </select>
          </div>
        </div>
      </div>
    )
      : (
        <div className="sound__item-creator-select-wrapper">
          <div className="sound__item-input-wrapper">
            <div className="sound__item-input-label">
              <label htmlFor="sound-producer">프로듀서</label>
            </div>
            <div className="sound__item-input">
              <select className="sound__item-input-narrator" id="sound-producer" onChange={this.handleProducerChange}>
                <option value="" disabled selected>프로듀서를 골라주세요</option>
                {creatorOptions}
              </select>
            </div>
          </div>
        </div>
      )
    ) : (this.state.isNarrationIncluded ? (
      <div className="sound__item-creator-select-wrapper">
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-script">스크립트 파일</label>
          </div>
          <div className="sound__item-input">
            <input type="file" id="sound-script" accept="application/pdf" onChange={this.handleScriptFileChange} />
          </div>
        </div>
      </div>
    ) : (
        null
      )
      )

    const scheduleDatePicker = this.state.isScheduled ? (
      <div className="sound__item-input-wrapper">
        <div className="sound__item-input-label schedule">
          <label>예약 날짜</label>
        </div>
        <div className="sound__item-input">
          <div className="sound__item-schedule-date-wrapper">
            <DateTimePicker
              onChange={this.handleScheduleDateChange}
              value={this.state.scheduleDate}
            />
          </div>
        </div>
      </div>
    ) : null

    return (
      <div className="sound__item">
        <h3>새 오디오 추가</h3>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-name">제목</label>
          </div>
          <div className="sound__item-input">
            <textarea type="text" id="sound-name" value={this.state.name} placeholder="오디오 제목" onChange={this.handleNameInput} />
          </div>
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-description">설명</label>
          </div>
          <div className="sound__item-input">
            <textarea id="sound-description" value={this.state.description} placeholder="오디오 설명" onChange={this.handleDescriptionInput} />
          </div>
        </div>
        {hashTagDropdown}
        <div className="sound__item-tag-select-wrapper">
          <div className="sound__item-input-wrapper">
            <div className="sound__item-input-label">
              <label htmlFor="sound-easy-play-tags">이지플레이태그</label>
            </div>
            <div className="sound__item-input">
              <Dropdown options={easyPlayTagOptions} onChange={this.handleEasyPlayTagSelect} placeholder="이지 플레이 태그를 선택해주세요" />
            </div>
            <div className="sound__tags-selected">
              {selectedEasyPlayTags}
            </div>
          </div>
        </div>
        <div className="easy-play-tag-notice">**이지플레이태그는 총 3개까지만 등록할 수 있습니다.</div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-file">오디오 파일</label>
          </div>
          <div className="sound__item-input">
            <input type="file" accept="audio/*" id="sound-file" onChange={this.handleFileChange} />
          </div>
          <audio id="audio__preview-full" controls />
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-priority">오디오 트랙 순서</label>
          </div>
          <div className="sound__item-input">
            <input type="number" id="sound-priority" value={this.state.priority} placeholder="채널 내 순서" onChange={this.handlePriorityInput} />
          </div>
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-is-premium">유료 곡 여부</label>
          </div>
          <div className="sound__item-input">
            <input type="checkbox" id="sound-is-premium" checked={this.state.isPremium} onChange={this.handleIsPremiumChange} />
          </div>
        </div>
        <div className="sound__item-input-wrapper sound-image">
          <div className="sound__item-input-label">
            <label htmlFor="sound-image">곡 커버 이미지</label>
          </div>
          <div className="sound__item-input">
            <input type="file" id="sound-image" accept="image/*" onChange={this.handleImageChange} />
          </div>
          <div className="sound__image-preview">
            {soundImagePreview}
          </div>
        </div>
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="sound-is-music-only">내레이션 여부</label>
          </div>
          <div className="sound__item-input">
            <input type="checkbox" id="sound-is-music-only" checked={this.state.isNarrationIncluded} onChange={this.handleIsNarrationIncludedChange} />
          </div>
        </div>
        {creatorSelect}
        <div className="sound__item-input-wrapper">
          <div className="sound__item-input-label">
            <label htmlFor="is_scheduled">예약 여부</label>
          </div>
          <div className="sound__item-input">
            <input type="checkbox" id="is_scheduled" checked={this.state.isScheduled} onChange={this.handleIsScheduleCheck} />
          </div>
        </div>
        {scheduleDatePicker}
        <div className="audio-quality-notice">채널 및 사운드의 퀄리티가 안 좋을 경우 서비스 제공이 잠시 중단될 수 있습니다. 중단될 경우, 루시드 매니저와 소통하며 긴밀하게 퀄리티를 올려 다시 업데이트 할 수 있습니다.</div>
        <div className="sound__item-btn-wrapper">
          <div className="submit" onClick={this.handleSubmit}>확인</div>
          <div className="cancel" onClick={this.handleCancel}>취소</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(NewSound))