let yearList = [];

for (let i = 1950; i < 2019; i++) {
  yearList.push(i.toString())
}
export const YEARS = yearList;

export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => i.toString())
export const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(i => i.toString())

export const asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const dateFormatter = function (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  return year + "년 " + month + "월 " + day + "일 " + hour + "시 " + minute + "분"
}

export const classDurationFormatter = function (days) {
  return (days % 7 === 0) ? (days / 7 === 1 ? "1 Week" : (days / 7) + " Weeks") : (days === 1 ? "1 Day" : days + " Days")
}