import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class ChannelList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      channels: []
    }
  }
  componentDidMount() {
    this.setState({ width: window.innerWidth })
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const channelItems = this.props.channels.length === 0 ? (
      <div className="no-channels">아직 등록된 채널이 없습니다.</div>
    ) : (
        this.props.channels.map(channel => {
          const imgElem = channel.image ? <div className="channel-item__wrapper"><img src={channel.image} alt="channel" /></div> : <div className="channel-item__wrapper"></div>
          return (
            <li className="channel-item" key={channel.id}>
              <Link to={"/channels/" + channel.id + "/show"} className="channel-edit__link-image">{imgElem}</Link>
              <div className="channel-edit__link-title"><Link to={"/channels/edit/" + channel.id} className="channel-edit__link">{channel.name}</Link></div>
            </li>
          )
        })
      )

    const gridNum = Math.floor((this.state.width - 328) / 200)
    return (
      <div className="channel-list-wrapper">
        <ul className="channel-list" style={{ gridTemplateColumns: 'repeat(' + gridNum + ', 1fr)' }} >
          {channelItems}
        </ul>

      </div>
    )
  }
}

export default ChannelList