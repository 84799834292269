import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Channels from '../pages/channels/Channels'
import ShowChannel from '../pages/channels/ShowChannel'
import EditChannel from '../pages/channels/EditChannel'
import NewChannel from '../pages/channels/NewChannel'
import NewSound from '../pages/sounds/NewSound'
import ShowSound from '../pages/sounds/ShowSound'
import EditSound from '../pages/sounds/EditSound'
import MyInfo from '../pages/mypage/MyInfo'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import Analytics from '../pages/mypage/Analytics';
import Premieres from '../pages/mypage/Premieres';
import RevenueShare from '../pages/mypage/RevenueShare';
import PaymentHistory from '../pages/mypage/PaymentHistory';

const PageView = (props) => {
  return (
    <div className="pageview">
      <Route exact path="/">
        {props.user !== null ? <Redirect to="/channels" /> : <Redirect to="login" />}
      </Route>
      <Route exact path="/channels/new">
        {props.user !== null ? <NewChannel /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/channels/:id/show">
        {props.user !== null ? <ShowChannel /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/channels/:id/edit">
        {props.user !== null ? <EditChannel /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/channels/:channel_id/sounds/new">
        {props.user !== null ? <NewSound /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/channels/:channel_id/sounds/:sound_id/show">
        {props.user !== null ? <ShowSound /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/channels/:channel_id/sounds/:sound_id/edit">
        {props.user !== null ? <EditSound /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/channels">
        {props.user !== null ? <Channels /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/mypage/info">
        {props.user !== null ? <MyInfo /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/mypage/analytics">
        {props.user !== null ? <Analytics /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/mypage/premieres">
        {props.user !== null ? <Premieres /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/mypage/revenue_share">
        {props.user !== null ? <RevenueShare /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/mypage/payment_histories">
        {props.user !== null ? <PaymentHistory /> : <Redirect to="/login" />}
      </Route>
      <Route path="/login">
        {props.user !== null ? <Redirect to="/channels" /> : <Login />}
      </Route>
      <Route path="/register">
        {props.user !== null ? <Redirect to="/channels" /> : <Register />}
      </Route>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps)(PageView);