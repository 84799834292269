import React, { Component } from 'react'
import { connect } from 'react-redux'
import client from '../../modules/client'

import { setUser } from '../../store/actions/userActions'
import { YEARS, MONTHS, DAYS } from '../../helpers'

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      name: '',
      nickname: '',
      description: '',
      gender: '',
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      profileImageFile: null,
      imagePreviewUrl: '',
      code: '',
      isEmailUnique: false,
      isNicknameUnique: false,
      isPasswordConfirmed: true,
    }
  }

  componentDidMount() {
    if (window.location.search) {
      const codeArray = window.location.search.slice(1).split("&").filter(item => item.split("=")[0] === "code")
      if (codeArray.length > 0) {
        const code = codeArray[0].split("=")[1]
        this.setState({
          code: code
        })
      }
    }
  }

  handleSubmit = e => {
    const birthDate = [this.state.birthYear, this.state.birthMonth, this.state.birthDay].join('-')
    e.preventDefault();
    let form_data = new FormData();
    form_data.append('email', this.state.email);
    form_data.append('password', this.state.password);
    form_data.append('name', this.state.name);
    form_data.append('nickname', this.state.nickname);
    form_data.append('description', this.state.description);
    form_data.append('gender', this.state.gender);
    form_data.append('birth_date', birthDate);
    form_data.append('profile_image', this.state.profileImageFile, this.state.profileImageFile.name);

    if (!this.state.isEmailUnique) {
      alert("이메일 중복확인을 해주세요.")
    } else if (!this.state.isNicknameUnique) {
      alert("닉네임 중복확인을 해주세요.")
    } else if (!this.state.code) {
      alert("이메일을 통해 전달받은 링크로만 가입이 가능합니다.")
    } else {
      client({
        method: 'post',
        url: '/register',
        data: form_data,
        headers: {
          'Code': this.state.code,
          'content-type': 'multipart/form-data'
        },
      }).then(res => {
        localStorage.setItem(
          "creatorToken",
          res.data.token
        );
        this.props.setUser(res.data.token)
      })
    }
  }

  handleEmailInput = (e) => {
    this.setState({
      email: e.target.value,
      isEmailUnique: false
    })
  }

  checkUniqueEmail = e => {
    e.preventDefault();
    client({
      method: 'post',
      url: '/check_unique_email',
      data: {
        email: this.state.email
      }
    }).then(res => {
      if (res.data.status === 0) {
        this.setState({
          isEmailUnique: true
        })
        alert("사용 가능한 이메일입니다.")
      } else {
        this.setState({
          isEmailUnique: false
        })
        alert("이미 사용중인 이메일입니다.")
      }
    }).catch(err => {
      alert("Error: ", err)
    })
  }

  handlePasswordInput = (e) => {
    this.setState({
      password: e.target.value,
      isPasswordConfirmed: false
    })
  }

  handlePasswordConfirmationInput = (e) => {
    if (this.state.password === e.target.value) {
      this.setState({
        passwordConfirmation: e.target.value,
        isPasswordConfirmed: true
      })
    } else {
      this.setState({
        passwordConfirmation: e.target.value,
        isPasswordConfirmed: false
      })
    }
  }

  handleNameInput = (e) => {
    this.setState({
      name: e.target.value
    })
  }

  handleNicknameInput = e => {
    this.setState({
      nickname: e.target.value,
      isNicknameUnique: false
    })
  }

  checkUniqueNickname = e => {
    e.preventDefault();
    client({
      method: 'post',
      url: '/check_unique_nickname',
      data: {
        nickname: this.state.nickname
      }
    }).then(res => {
      if (res.data.status === 0) {
        this.setState({
          isNicknameUnique: true
        })
        alert("사용 가능한 닉네임입니다.")
      } else {
        this.setState({
          isNicknameUnique: false
        })
        alert("이미 사용중인 닉네임입니다.")
      }
    }).catch(err => {
      alert("Error: ", err)
    })
  }

  handleDescriptionInput = e => {
    this.setState({
      description: e.target.value
    })
  }

  handleGenderChange = e => {
    this.setState({
      gender: e.target.value
    })
  }

  handleBirthYearChange = e => {
    this.setState({
      birthYear: e.target.value
    })
  }

  handleBirthMonthChange = e => {
    this.setState({
      birthMonth: e.target.value
    })
  }

  handleBirthDayChange = e => {
    this.setState({
      birthDay: e.target.value
    })
  }

  handleProfileImageFileChange = e => {
    e.preventDefault();

    let file = e.target.files[0];

    this.setState({
      profileImageFile: file,
      imagePreviewUrl: URL.createObjectURL(file)
    })
  }

  render() {
    const { imagePreviewUrl } = this.state;
    const imagePreview = imagePreviewUrl ? (<img src={imagePreviewUrl} className="preview-img" alt="preview" />) : (<div className="previewText">프로필 이미지를 선택해주세요</div>)
    const birthYearOptions = YEARS.map(year => <option key={year} value={year}>{year}</option>)
    const birthMonthOptions = MONTHS.map(month => <option key={month} value={month}>{month}</option>)
    const birthDayOptions = DAYS.map(day => <option key={day} value={day}>{day}</option>)
    return (
      <div className="register">
        <div className="register__form">
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label htmlFor="email">이메일</label>
            </div>
            <div className="register__input">
              <input type="text" id="email" placeholder="이메일을 입력해주세요." onChange={this.handleEmailInput} />
            </div>
            <div className="uniq-check" onClick={this.checkUniqueEmail}>중복확인</div>
          </div>
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label htmlFor="password">비밀번호</label>
            </div>
            <div className="register__input">
              <input type="password" id="password" placeholder="비밀번호를 입력해주세요." onChange={this.handlePasswordInput} />
            </div>
          </div>
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label htmlFor="password_confirmation">비밀번호 확인</label>
            </div>
            <div className="register__input">
              <input type="password" id="password_confirmation" placeholder="위와 동일한 비밀번호를 입력해주세요." onChange={this.handlePasswordConfirmationInput} onBlur={this.handlePasswordConfirmationBlur} />
            </div>
            {this.state.isPasswordConfirmed ? '' : <div className="password-error">비밀번호가 틀립니다.</div>}
          </div>
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label htmlFor="name">이름</label>
            </div>
            <div className="register__input">
              <input type="text" id="name" placeholder="이름을 입력해주세요." onChange={this.handleNameInput} />
            </div>
          </div>
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label htmlFor="nickname">닉네임</label>
            </div>
            <div className="register__input">
              <input type="text" id="nickname" placeholder="사용할 닉네임을 입력해주세요." onChange={this.handleNicknameInput} />
            </div>
            <div className="uniq-check" onClick={this.checkUniqueNickname}>중복확인</div>
          </div>
          <div className="register__input-wrapper description">
            <div className="register__input-label">
              <label htmlFor="description">한마디 자기소개</label>
            </div>
            <div className="register__input">
              <textarea id="description" placeholder="한마디 자기소개를 입력해주세요." onChange={this.handleDescriptionInput} />
            </div>
          </div>
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label htmlFor="gender">성별</label>
            </div>
            <div className="register__input gender-select">
              <select className="register__input-gender" id="gender" onChange={this.handleGenderChange}>
                <option value="">성별을 선택해주세요</option>
                <option value="female">여자</option>
                <option value="male">남자</option>
              </select>
            </div>
          </div>
          <div className="register__input-wrapper">
            <div className="register__input-label">
              <label>생년월일</label>
            </div>
            <div className="register__birthdate-select-wrapper">
              <div className="register__input">
                <select id="birthYear" onChange={this.handleBirthYearChange}>
                  <option value="">년</option>
                  {birthYearOptions}
                </select>
              </div>
              <div className="register__input">
                <select id="birthMonth" onChange={this.handleBirthMonthChange}>
                  <option value="">월</option>
                  {birthMonthOptions}
                </select>
              </div>
              <div className="register__input">
                <select id="birthDay" onChange={this.handleBirthDayChange}>
                  <option value="">일</option>
                  {birthDayOptions}
                </select>
              </div>
            </div>
          </div>
          <div className="register__input-wrapper profile-image">
            <div className="register__input-label">
              <label htmlFor="profile_image">프로필 이미지</label>
            </div>
            <div className="register__input">
              <input type="file" id="profile_image" accept="image/*" onChange={this.handleProfileImageFileChange} />
            </div>
            <div className="profile-image-preview">
              {imagePreview}
            </div>
          </div>
          <div className="register__submit-btn" onClick={this.handleSubmit}>루시디스트 등록</div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(setUser(user))
  }
}

export default connect(null, mapDispatchToProps)(Register)