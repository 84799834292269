import React, { Component } from 'react'
import client from '../../modules/client'
import { connect } from 'react-redux'

import loadingIcon from '../../assets/images/Ripple-1s-200px.svg'

class RevenueShareDetails extends Component {
  state = {
    loaded: false,
    income: 0,
    sounds: []
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/revenue_share/' + this.props.year + '/' + this.props.month,
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      this.setState({
        loaded: true,
        income: res.data.data.expected_income,
        sounds: res.data.data.sounds
      })
    }).catch(err => {
      console.log(err)
    })
  }

  closePopup = e => {
    this.props.closeRsPopup(e);
  }

  render() {
    if (!this.state.loaded) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }

    const soundsList = this.state.sounds.length > 0 ? this.state.sounds.map(item => {
      const isWriter = item.is_writer ? <div className="writer role-content">작가</div> : null
      const isNarrator = item.is_narrator ? <div className="narrator role-content">내레이터</div> : null
      const isProducer = item.is_producer ? <div className="producer role-content">프로듀서</div> : null
      return (
        <li key={item.id} className="expected-income-table-item">
          <div className="name">{item.name}</div>
          <div className="role">
            {isWriter}
            {isNarrator}
            {isProducer}
          </div>
          <div className="share-amount">{Math.floor(item.share_amount)}원</div>
        </li>
      )
    }) : <li className="expected-income-table-item no-item">
        <div className="no-content">내역이 없습니다.</div>
      </li>

    return (
      <div className="revenue-share-details">
        <div className="revenue-share-background"></div>
        <div className="revenue-share-details-wrapper">
          <div className="revenue-share-container">
            <div className="close" onClick={this.closePopup}>닫기</div>
            <div className="title">{this.props.year}년 {this.props.month}월 상세 내역</div>
            <div className="total-income">{Math.floor(this.state.income)}원</div>
            <div className="expected-income-details">
              <div className="expected-income-details-title">오디오별 상세 내역</div>
              <div className="expected-income-table">
                <div className="expected-income-table-legend">
                  <div className="name legend">오디오 이름</div>
                  <div className="role legend">역할</div>
                  <div className="share-amount legend">금액</div>
                </div>
                <ul className="expected-income-table-content">
                  {soundsList}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(RevenueShareDetails)