import React, { Component } from 'react'
import client from '../../modules/client'
import { connect } from 'react-redux'

import loadingIcon from '../../assets/images/Ripple-1s-200px.svg'
import { dateFormatter } from '../../helpers'

class PaymentHistory extends Component {
  state = {
    loaded: false,
    paymentHistories: [],
    showCancelRequestPopup: false,
    idToCancel: null
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/payment_histories',
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      this.setState({
        loaded: true,
        paymentHistories: res.data.data
      })
    }).catch(err => {
      console.error(err)
    })
  }

  handleCancelRequest = (id, e) => {
    console.log(id)
    this.setState({
      showCancelRequestPopup: true,
      idToCancel: id
    })
  }

  submitCancelRequest = e => {
    client({
      method: 'get',
      url: '/payment_histories/' + this.state.idToCancel,
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      this.setState({
        loaded: true,
        paymentHistories: res.data.data,
        showCancelRequestPopup: false,
        idToCancel: null
      })
    }).catch(err => {
      console.error(err)
    })
  }

  closePopup = e => {
    this.setState({
      showCancelRequestPopup: false,
      idToCancel: null
    })
  }

  render() {
    if (!this.state.loaded) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }

    const paymentHistoryList = this.state.paymentHistories.length > 0 ? this.state.paymentHistories.map(item => {
      const createdAt = new Date(item.created_at * 1000)

      const status = item.paid ? <div className="paid status-content">출금 완료</div>
        : item.canceled ? <div className="canceled status-content">취소됨</div>
          : <div className="pending status-content">출금 대기</div>
      const paidAt = item.paid ? <div className="paid-at">{dateFormatter(new Date(item.paid_at * 1000))}</div>
        : item.canceled ? <div className="paid-at">-</div>
          : <div className="paid-at cancel" onClick={e => this.handleCancelRequest(item.id, e)}>출금 취소</div>

      const amount = item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return (
        <li key={item.id} className="ph-table-item">
          <div className="date">{dateFormatter(createdAt)}</div>
          <div className="amount">{amount}원</div>
          <div className="status">{status}</div>
          <div className="processed-at">{paidAt}</div>
        </li>
      )
    }) : <li className="ph-table-item no-item">
        <div className="no-content">내역이 없습니다.</div>
      </li>

    const cancelPaymentPopup = this.state.showCancelRequestPopup ?
      <div className="ph-popup">
        <div className="ph-background"></div>
        <div className="ph-popup-wrapper">
          <div className="ph-popup-container">
            <div className="title">정말로 취소하시겠습니까?</div>
            <div className="btns-wrapper">
              <div className="submit" onClick={this.submitCancelRequest}>출금 취소</div>
              <div className="cancel" onClick={this.closePopup}>닫기</div>
            </div>
          </div>
        </div>
      </div>
      : null
    return (
      <div className="payment-history">
        <h1>출금 내역</h1>
        <div className="payment-history-wrapper">
          <div className="ph-table">
            <div className="ph-table-legend">
              <div className="date legend">신청일</div>
              <div className="amount legend">금액</div>
              <div className="status legend">상태</div>
              <div className="processed-at legend">출금일시</div>
            </div>
            <ul className="ph-table-content">
              {paymentHistoryList}
            </ul>
          </div>
        </div>
        {cancelPaymentPopup}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(PaymentHistory)