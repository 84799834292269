import React, { Component } from 'react'
import { connect } from 'react-redux'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import PageView from './PageView'

class Frame extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="frame">
        <Navbar />
        <div className="wrapper">
          {user !== null ? <Sidebar /> : null}
          <PageView />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(Frame);