import React, { Component } from 'react'
import { connect } from 'react-redux'
import client from "../../modules/client"

import { setUser } from '../../store/actions/userActions';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    }

    this.submitLoginForm = this.submitLoginForm.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.submitLoginForm(e)
    }
  }

  submitLoginForm(e) {
    e.preventDefault();
    client({
      method: 'post',
      url: '/login',
      data: {
        email: this.state.email,
        password: this.state.password
      }
    }).then(res => {
      localStorage.setItem(
        "creatorToken",
        res.data.data.token
      );
      this.props.setUser({ token: res.data.data.token, isSuperUser: res.data.data.is_super_user })
    }).catch(err => {
      alert("이메일 혹은 비밀번호가 틀립니다. 다시 시도해주세요.")
    })

  }

  handleEmailInput = (e) => {
    this.setState({
      email: e.target.value
    })
  }
  handlePasswordInput = (e) => {
    this.setState({
      password: e.target.value
    })
  }


  render() {
    return (
      <div className="login">
        <div className="login__form">
          <div className="login__input-wrapper">
            <div className="login__input-label">
              <label htmlFor="email">이메일</label>
            </div>
            <div className="login__input">
              <input type="text" id="email" onChange={this.handleEmailInput} />
            </div>
          </div>
          <div className="login__input-wrapper">
            <div className="login__input-label">
              <label htmlFor="password">비밀번호</label>
            </div>
            <div className="login__input">
              <input type="password" id="password" onChange={this.handlePasswordInput} onKeyUp={this.handleKeyUp} />
            </div>
          </div>
          <div className="login__button" onClick={this.submitLoginForm}>
            로그인
          </div>
        </div>
        <div className="no-account">
          루시디스트 계정이 없으시다면 <a href="https://forms.gle/cMCJdFmkQhmbnqTA7" className="register-link" target="_blank" rel="noopener noreferrer">여기</a>를 통해 루시디스트 프로그램에 지원해주세요.
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(setUser(user))
  }
}

export default connect(null, mapDispatchToProps)(Login)