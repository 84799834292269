import React, { Component } from 'react'
import client from '../../modules/client';
import { connect } from 'react-redux'

class PasswordChangePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      doPasswordsMatch: true
    }
  }

  componentDidMount() {
    document.getElementById("password").focus();
  }

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value
    })
  }

  handleNewPasswordChange = e => {
    this.setState({
      newPassword: e.target.value
    }, () => {
      if (this.state.newPassword !== this.state.newPasswordConfirmation) {
        this.setState({ doPasswordsMatch: false })
      } else {
        this.setState({ doPasswordsMatch: true })
      }
    })
  }

  handleNewPasswordConfirmationChange = e => {
    this.setState({
      newPasswordConfirmation: e.target.value
    }, () => {
      if (this.state.newPassword !== this.state.newPasswordConfirmation) {
        this.setState({ doPasswordsMatch: false })
      } else {
        this.setState({ doPasswordsMatch: true })
      }
    })
  }

  handleKeyUp = e => {
    if (e.keyCode === 13) {
      this.submit(e);
    }
  }

  submit = e => {
    client({
      method: 'patch',
      url: '/me/password',
      headers: {
        'Authorization': "Bearer " + this.props.user.token
      },
      data: {
        password: this.state.password,
        new_password: this.state.newPassword,
        new_password_confirmation: this.state.newPasswordConfirmation
      }
    }).then(res => {
      if (res.status === 200) {
        this.props.handleChangedPassword()
      } else {
        console.log(res)
      }
    })
  }

  render() {
    return (
      <div className="popup password-change">
        <div className="popup-wrapper">
          <div className="popup__input-wrapper">
            <div className="popup__input-label">
              <label htmlFor="password">기존 비밀번호</label>
            </div>
            <div className="popup__input">
              <input type="password" id="password" onChange={this.handlePasswordChange} value={this.state.password} />
            </div>
          </div>
          <div className="popup__input-wrapper">
            <div className="popup__input-label">
              <label htmlFor="new_password">새로운 비밀번호</label>
            </div>
            <div className="popup__input">
              <input type="password" id="new_password" onChange={this.handleNewPasswordChange} value={this.state.newPassword} />
            </div>
          </div>
          <div className="popup__input-wrapper">
            <div className="popup__input-label">
              <label htmlFor="new_password-confirmation">새로운 비밀번호 확인</label>
            </div>
            <div className="popup__input">
              <input type="password" id="new_password-confirmation" onChange={this.handleNewPasswordConfirmationChange} value={this.state.newPasswordConfirmation} onKeyUp={this.handleKeyUp} />
            </div>
          </div>
          <button className="popup__submit-btn" disabled={!this.state.doPasswordsMatch || !this.state.newPassword} onClick={this.submit}>비밀번호 변경</button>
          <button className="close-popup" onClick={this.props.closePopup}>닫기</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(PasswordChangePopup)