import React, { Component } from 'react'
import client from '../../modules/client'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { MONTHS } from '../../helpers'
import bestIcon from '../../assets/images/icBestRed164@3x.png'
import SoundList from '../../components/sounds/SoundList'
import { dateFormatter, classDurationFormatter } from '../../helpers'

const LIVE_SOUNDS = 0
const SCHEDULED_SOUNDS = 1

class ShowChannel extends Component {
  state = {
    loaded: false,
    name: '',
    description: '',
    image: '',
    bgColor: '',
    divisionId: '',
    tagId: '',
    divisions: [],
    tags: [],
    sounds: [],
    comments: [],
    creators: [],
    selectedTab: LIVE_SOUNDS,
    isScheduled: false,
    scheduleDate: new Date(),
    isFinished: false,
    isClass: false,
    classDuration: null,
  }

  async componentDidMount() {
    try {
      const res = await client({
        method: 'get',
        url: '/divisions',
      })
      const divisions = res.data.data;
      this.setState({ divisions })

      client({
        method: 'get',
        url: '/tags_all'
      }).then(res => {
        const tags = res.data.data;
        this.setState({ tags })
      })

      client({
        method: 'get',
        url: '/creators'
      }).then(res => {
        const creators = res.data.data;
        this.setState({ creators })
      })
    } catch (err) {
      console.log(err)
    }

    try {
      const channelId = this.props.location.pathname.split("/")[2]
      const res = await client({
        method: 'get',
        url: '/channels/' + channelId,
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })
      const channelData = await res.data.data;
      if (channelData.is_live) {
        await this.setState({
          name: channelData.name || '',
          description: channelData.description || '',
          divisionId: channelData.division_id || '',
          tagId: channelData.tag_id || '',
          image: channelData.image || '',
          bgColor: channelData.bg_color || '',
          isFinished: channelData.is_finished,
          isClass: channelData.is_class,
          classDuration: channelData.class_duration,
        })
      } else {
        const scheduleRes = await client({
          method: 'get',
          url: '/channels/' + channelId + '/schedule',
          headers: {
            'Authorization': 'Bearer ' + this.props.user.token
          }
        })
        const timestamp = Date.parse(scheduleRes.data.data.date)
        const date = new Date(timestamp)
        this.setState({
          name: channelData.name || '',
          description: channelData.description || '',
          divisionId: channelData.division_id || '',
          tagId: channelData.tag_id || '',
          image: channelData.image || '',
          bgColor: channelData.bg_color || '',
          isScheduled: true,
          scheduleDate: date,
          isFinished: channelData.is_finished,
          isClass: channelData.is_class,
          classDuration: channelData.class_duration,
        })
      }

      const soundsRes = await client({
        method: 'get',
        url: '/channels/' + channelId + '/sounds',
        params: {
          scheduled: false
        },
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })

      const soundsData = await soundsRes.data.data;
      let soundList = [];
      soundsData.forEach(sound => {
        const soundItem = {
          name: sound.name || '',
          description: sound.description || '',
          durationMin: sound.duration || '',
          durationSec: sound.duration_sec || '',
          soundFilePreviewUrl: sound.file || '',
          soundShortFilePreviewUrl: sound.file_short_version || '',
          imagePreviewUrl: sound.image || '',
          priority: sound.priority || '',
          isPremium: sound.is_premium || false,
          isNarrationIncluded: sound.narrator_id !== null ? true : false,
          writerId: sound.writer_id !== null ? sound.writer_id : '',
          narratorId: sound.narrator_id !== null ? sound.narrator_id : '',
          producerId: sound.producer_id !== null ? sound.producer_id : '',
          script: sound.script || '',
          id: sound.id,
        }
        soundList.push(soundItem)
      })

      await this.setState({ sounds: soundList })

      const commentsRes = await client({
        method: 'get',
        url: '/channels/' + channelId + '/comments',
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })

      const commentsData = await commentsRes.data.data;
      let commentList = [];
      commentsData.forEach(comment => {
        const date = new Date(comment.created_at * 1000);
        const year = date.getFullYear();
        const month = MONTHS[date.getMonth()];
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();
        const seconds = "0" + date.getSeconds();

        const createdAt = year + '년 ' + month + '월 ' + day + '일 ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        const commentItem = {
          id: comment.id,
          commenter: {
            profileImageUrl: comment.commenter.profile_image,
            name: comment.commenter.name
          },
          content: comment.content,
          isBest: comment.is_best,
          likesCount: comment.likes_count,
          createdAt: createdAt
        }
        commentList.push(commentItem)
      })

      await this.setState({
        comments: commentList
      })

      await this.setState({
        loaded: true
      })
    } catch (err) {
      console.log("channel get err: ", err)
    }
  }

  handleLiveSoundsTabClick = e => {
    if (this.state.selectedTab === SCHEDULED_SOUNDS) {
      const channelId = this.props.location.pathname.split("/")[2]
      client({
        method: 'get',
        url: '/channels/' + channelId + '/sounds',
        params: {
          scheduled: false
        },
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      }).then(res => {
        const soundsData = res.data.data;
        let soundList = [];
        soundsData.forEach(sound => {
          const soundItem = {
            name: sound.name || '',
            description: sound.description || '',
            durationMin: sound.duration || '',
            durationSec: sound.duration_sec || '',
            soundFilePreviewUrl: sound.file || '',
            soundShortFilePreviewUrl: sound.file_short_version || '',
            imagePreviewUrl: sound.image || '',
            priority: sound.priority || '',
            isPremium: sound.is_premium || false,
            isNarrationIncluded: sound.narrator_id !== null ? true : false,
            writerId: sound.writer_id !== null ? sound.writer_id : '',
            narratorId: sound.narrator_id !== null ? sound.narrator_id : '',
            producerId: sound.producer_id !== null ? sound.producer_id : '',
            script: sound.script || '',
            id: sound.id,
          }
          soundList.push(soundItem)
        })
        this.setState({ sounds: soundList, selectedTab: LIVE_SOUNDS, loaded: true })
      })
    }
  }

  handleScheduledSoundsTabClick = e => {
    if (this.state.selectedTab === LIVE_SOUNDS) {
      const channelId = this.props.location.pathname.split("/")[2]
      client({
        method: 'get',
        url: '/channels/' + channelId + '/sounds',
        params: {
          scheduled: true
        },
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      }).then(res => {
        const soundsData = res.data.data;
        let soundList = [];
        soundsData.forEach(sound => {
          const soundItem = {
            name: sound.name || '',
            description: sound.description || '',
            durationMin: sound.duration || '',
            durationSec: sound.duration_sec || '',
            soundFilePreviewUrl: sound.file || '',
            soundShortFilePreviewUrl: sound.file_short_version || '',
            imagePreviewUrl: sound.image || '',
            priority: sound.priority || '',
            isPremium: sound.is_premium || false,
            isNarrationIncluded: sound.narrator_id !== null ? true : false,
            writerId: sound.writer_id !== null ? sound.writer_id : '',
            narratorId: sound.narrator_id !== null ? sound.narrator_id : '',
            producerId: sound.producer_id !== null ? sound.producer_id : '',
            script: sound.script || '',
            id: sound.id,
          }
          soundList.push(soundItem)
        })
        this.setState({ sounds: soundList, selectedTab: SCHEDULED_SOUNDS, loaded: true })
      })
    }
  }

  handleDeleteChannel = e => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const channelId = this.props.location.pathname.split("/")[2]
      client({
        method: 'delete',
        url: '/channels/' + channelId,
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      }).then(res => {
        this.props.history.push("/channels")
      })
    }
  }

  render() {
    const division = this.state.loaded ? this.state.divisions.filter(item => item.id === this.state.divisionId)[0].name : ''
    const tag = this.state.loaded ? this.state.tags.filter(item => item.id === this.state.tagId)[0].name : ''
    const thumbnailPreview = this.state.loaded ? (<img src={this.state.image} className="preview-img" alt="preview" />) : (<div className="preview-text">썸네일이 없습니다.</div>)
    const liveSoundClass = this.state.selectedTab === LIVE_SOUNDS ? "sounds__navbar-tab selected" : "sounds__navbar-tab"
    const scheduleSoundClass = this.state.selectedTab === SCHEDULED_SOUNDS ? "sounds__navbar-tab selected" : "sounds__navbar-tab"

    const commentItems = this.state.comments.length === 0 ? (
      <div className="no-comment">아직 남겨진 한마디가 없습니다.</div>
    ) : this.state.comments.map(commentItem => {
      const commentClass = commentItem.isBest ? "comment__item best" : "comment__item";
      const isBest = commentItem.isBest ? <div className="best-icon"><img src={bestIcon} alt="best" /></div> : ''
      return (
        <div className={commentClass} key={commentItem.id}>
          {isBest}
          <div className="created-at">{commentItem.createdAt}</div>
          <div className="commenter-image">
            <img src={commentItem.commenter.profileImageUrl} alt="commenter" />
          </div>
          <div className="commenter-name">{commentItem.commenter.name}</div>
          <div className="content">{commentItem.content}</div>
          <div className="likes-count">{commentItem.likesCount}</div>
        </div>
      )
    })

    const scheduleDate = this.state.isScheduled ? (
      <div className="channel__show-wrapper">
        <div className="channel__show-label">
          <label htmlFor="schedule_date">예약 날짜</label>
        </div>
        <div className="channel__show">
          <div className="channel__show-content" id="schedule_date">{dateFormatter(this.state.scheduleDate)}</div>
        </div>
      </div>
    ) : null

    const classDurationDiv = this.state.isClass ? (
      <div className="channel__show-wrapper">
        <div className="channel__show-label">
          <label htmlFor="class_duration">기간</label>
        </div>
        <div className="channel__show">
          <div className="channel__show-content" id="class_duration">{classDurationFormatter(this.state.classDuration)}</div>
        </div>
      </div>
    ) : null

    const channelDeleteBtn = this.state.isScheduled ? (
      <div className="channel__delete-btn" onClick={this.handleDeleteChannel}>삭제</div>
    ) : null

    return (
      <div className="channel">
        <h3>채널 정보</h3>
        <div className="channel__form">
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="name">채널 이름</label>
            </div>
            <div className="channel__show">
              <div className="channel__show-content" id="name">{this.state.name}</div>
            </div>
          </div>
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="description">채널 소개</label>
            </div>
            <div className="channel__show">
              <div className="channel__show-content" id="description">{this.state.description}</div>
            </div>
          </div>
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="division">채널 카테고리</label>
            </div>
            <div className="channel__show">
              <div className="channel__show-content" id="division">{division}</div>
            </div>
          </div>
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="tag">섹션</label>
            </div>
            <div className="channel__show">
              <div className="channel__show-content" id="tag">{tag}</div>
            </div>
          </div>
          <div className="channel__show-wrapper channel-image">
            <div className="channel__show-label">
              <label htmlFor="channel_thumbnail">채널 썸네일</label>
            </div>
            <div className="channel__image-preview">
              {thumbnailPreview}
            </div>
          </div>
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="bg_color">채널 배경색</label>
            </div>
            <div className="channel__show">
              <div className="channel__show-content" id="bg_color">{this.state.bgColor}</div>
            </div>
          </div>
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="is_scheduled">예약 여부</label>
            </div>
            <div className="channel__show">
              <input type="checkbox" id="is_scheduled" checked={this.state.isScheduled} disabled />
            </div>
          </div>
          {scheduleDate}
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="is_finished">완결 여부</label>
            </div>
            <div className="channel__show">
              <input type="checkbox" id="is_finished" checked={this.state.isFinished} disabled />
            </div>
          </div>
          <div className="channel__show-wrapper">
            <div className="channel__show-label">
              <label htmlFor="is_class">클래스 채널</label>
            </div>
            <div className="channel__show">
              <input type="checkbox" id="is_class" checked={this.state.isClass} disabled />
            </div>
          </div>
          {classDurationDiv}
        </div>
        <div className="channel__edit-btn-div">
          <Link to={"/channels/" + this.props.location.pathname.split("/")[2] + "/edit"} className="channel__edit-btn">채널 수정하기</Link>
          {channelDeleteBtn}
        </div>
        <div className="sound__form">
          <h6>오디오 리스트</h6>
          <div className="navbar-sounds">
            <ul className="sounds__navbar-list">
              <li className={liveSoundClass}>
                <div className="btn__sounds-navbar" onClick={this.handleLiveSoundsTabClick}>
                  공개된 오디오
                </div>
              </li>
              <li className={scheduleSoundClass}>
                <div className="btn__sounds-navbar" onClick={this.handleScheduledSoundsTabClick}>
                  예약된 오디오
                </div>
              </li>
            </ul>
            <Link to={"/channels/" + this.props.location.pathname.split("/")[2] + "/sounds/new"} className="sound__add-btn" onClick={this.handleAddSoundClick}>오디오 추가</Link>
          </div>
          <SoundList sounds={this.state.sounds} channelId={this.props.location.pathname.split("/")[2]} />
        </div>
        <div className="comment__list">
          <h6>채널 댓글</h6>
          <div className="comment__items-wrapper">
            <div className="comment__item legend">
              <div className="label__created-at">일시</div>
              <div className="label__commenter">사용자</div>
              <div className="label__content">댓글</div>
              <div className="label__likes-count">좋아요 수</div>
            </div>
            {commentItems}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(ShowChannel))