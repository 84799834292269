import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import client from '../../modules/client'
import { dateFormatter } from '../../helpers'

class ShowSound extends Component {
  state = {
    loaded: false,
    name: '',
    description: '',
    priority: '',
    image: '',
    sound: '',
    file: '',
    isNarrationIncluded: false,
    writer: '',
    narrator: '',
    producer: '',
    isPremium: false,
    isScheduled: false,
    scheduleDate: new Date(),
    hashTags: [],
    easyPlayTags: [],
    divisionId: ''
  }

  async componentDidMount() {
    try {
      const channelId = this.props.location.pathname.split("/")[2]
      const soundId = this.props.location.pathname.split("/")[4]
      const creatorsRes = await client({
        method: 'get',
        url: '/creators'
      })
      const creators = creatorsRes.data.data
      const soundRes = await client({
        method: 'get',
        url: '/channels/' + channelId + '/sounds/' + soundId,
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })
      const channelRes = await client({
        method: 'get',
        url: '/channels/' + channelId,
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })
      const divisionId = channelRes.data.data.division_id
      const soundData = soundRes.data.data
      const writer = soundData.writer_id !== null ? creators.filter(creator => creator.id === soundData.writer_id)[0].name : ''
      const narrator = soundData.narrator_id !== null ? creators.filter(creator => creator.id === soundData.narrator_id)[0].name : ''
      const producer = soundData.producer_id !== null ? creators.filter(creator => creator.id === soundData.producer_id)[0].name : ''
      const hashTags = soundData.hash_tags
      const easyPlayTags = soundData.easy_play_tags
      if (soundData.is_live) {
        this.setState({
          name: soundData.name || '',
          description: soundData.description || '',
          priority: soundData.priority || '',
          image: soundData.image || '',
          file: soundData.file || '',
          isPremium: soundData.is_premium,
          isNarrationIncluded: soundData.narrator_id !== null ? true : false,
          writer: writer,
          narrator: narrator,
          producer: producer,
          loaded: true,
          hashTags,
          easyPlayTags,
          divisionId
        })
      } else {
        const scheduleRes = await client({
          method: 'get',
          url: '/sounds/' + soundId + '/schedule',
          headers: {
            'Authorization': 'Bearer ' + this.props.user.token
          }
        })
        const timestamp = Date.parse(scheduleRes.data.data.date)
        const date = new Date(timestamp)
        this.setState({
          name: soundData.name || '',
          description: soundData.description || '',
          priority: soundData.priority || '',
          image: soundData.image || '',
          file: soundData.file || '',
          isPremium: soundData.is_premium,
          isNarrationIncluded: soundData.narrator_id !== null ? true : false,
          writer: writer,
          narrator: narrator,
          producer: producer,
          isScheduled: true,
          scheduleDate: date,
          loaded: true,
          hashTags,
          easyPlayTags,
          divisionId
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleDeleteSound = e => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const soundId = this.props.location.pathname.split("/")[4]
      client({
        method: 'delete',
        url: '/sounds/' + soundId,
        headers: {
          'Authorization': 'Bearer' + this.props.user.token
        }
      }).then(res => {
        this.props.history.push("/channels/" + this.props.location.pathname.split("/")[2] + '/show')
      })
    }
  }

  render() {
    const hashTagList = this.state.hashTags.map(hashTag => {
      return (<div className="tag show-sound" key={hashTag.id} data-hashtag={hashTag.id + "%" + hashTag.name}>
        <span>{hashTag.name}</span>
      </div>)
    })
    const hashTagsDiv = this.state.divisionId === 1 ? (
      <div className="sound__item-show-wrapper">
        <div className="sound__item-show-label">
          <label htmlFor="sound-hashtags">해쉬태그</label>
        </div>
        <div className="sound__tags-selected show-sound" id="sound-hashtags">
          {hashTagList}
        </div>
      </div>
    ) : null
    const easyPlayTagList = this.state.easyPlayTags.map(easyPlayTag => {
      return (<div className="tag show-sound" key={easyPlayTag.id} data-easyplaytag={easyPlayTag.id + "%" + easyPlayTag.name}>
        <span>{easyPlayTag.name}</span>
      </div>)
    })
    const easyPlayTagsDiv = this.state.divisionId === 1 ? (
      <div className="sound__item-show-wrapper">
        <div className="sound__item-show-label">
          <label htmlFor="sound-easyplaytags">이지플레이태그</label>
        </div>
        <div className="sound__tags-selected show-sound" id="sound-easyplaytags">
          {easyPlayTagList}
        </div>
      </div>
    ) : null
    const soundImagePreview = this.state.image !== '' ? (<img src={this.state.image} className="sound-preview-img" alt="preview" />) : (<div className="sound-preview-text">곡 커버 이미지가 없습니다.</div>)
    const writerDiv = this.state.isNarrationIncluded ? (
      <div className="sound__item-show-wrapper">
        <div className="sound__item-show-label">
          <label htmlFor="writer">작가</label>
        </div>
        <div className="sound__item-show">
          <div className="sound__item-show-content" id="writer">{this.state.writer}</div>
        </div>
      </div>
    ) : null
    const narratorDiv = this.state.isNarrationIncluded ? (
      <div className="sound__item-show-wrapper">
        <div className="sound__item-show-label">
          <label htmlFor="narrator">내레이터</label>
        </div>
        <div className="sound__item-show">
          <div className="sound__item-show-content" id="narrator">{this.state.narrator}</div>
        </div>
      </div>
    ) : null
    const scheduleDate = this.state.isScheduled ? (
      <div className="sound__item-show-wrapper">
        <div className="sound__item-show-label">
          <label htmlFor="schedule_date">예약 날짜</label>
        </div>
        <div className="sound__item-show">
          <div className="sound__item-show-content" id="schedule_date">{dateFormatter(this.state.scheduleDate)}</div>
        </div>
      </div>
    ) : null
    const soundDeleteBtn = this.state.isScheduled ? (
      <div className="sound__item-delete-btn" onClick={this.handleDeleteSound}>삭제</div>
    ) : null
    return (
      <div className="sound__item">
        <h3>오디오 정보</h3>
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="sound-name">제목</label>
          </div>
          <div className="sound__item-show">
            <div className="sound__item-show-content" id="sound-name">{this.state.name}</div>
          </div>
        </div>
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="sound-description">설명</label>
          </div>
          <div className="sound__item-show">
            <div className="sound__item-show-content" id="sound-description">{this.state.description}</div>
          </div>
        </div>
        {hashTagsDiv}
        {easyPlayTagsDiv}
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="sound-file">오디오 파일</label>
          </div>
          <audio id="audio__preview-full" controls src={this.state.file} />
        </div>
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="sound-priority">오디오 트랙 순서</label>
          </div>
          <div className="sound__item-show">
            <div className="sound__item-show-content" id="sound-priority">{this.state.priority}</div>
          </div>
        </div>
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="sound-is-premium">유료 곡 여부</label>
          </div>
          <div className="sound__item-show">
            <input type="checkbox" id="sound-is-premium" checked={this.state.isPremium} disabled />
          </div>
        </div>
        <div className="sound__item-show-wrapper sound-image">
          <div className="sound__item-show-label">
            <label htmlFor="sound-image">곡 커버 이미지</label>
          </div>
          <div className="sound__image-preview">
            {soundImagePreview}
          </div>
        </div>
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="sound-is-music-only">내레이션 여부</label>
          </div>
          <div className="sound__item-show">
            <input type="checkbox" id="sound-is-music-only" checked={this.state.isNarrationIncluded} disabled />
          </div>
        </div>
        {writerDiv}
        {narratorDiv}
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="producer">프로듀서</label>
          </div>
          <div className="sound__item-show">
            <div className="sound__item-show-content" id="producer">{this.state.producer}</div>
          </div>
        </div>
        <div className="sound__item-show-wrapper">
          <div className="sound__item-show-label">
            <label htmlFor="is_scheduled">예약 여부</label>
          </div>
          <div className="sound__item-show">
            <input type="checkbox" id="is_scheduled" checked={this.state.isScheduled} disabled />
          </div>
        </div>
        {scheduleDate}
        <div className="sound__item-btn-wrapper">
          <Link to={"/channels/" + this.props.location.pathname.split("/")[2] + "/sounds/" + this.props.location.pathname.split("/")[4] + "/edit"} className="sound__item-edit-btn">수정</Link>
          {soundDeleteBtn}
          <Link to={"/channels/" + this.props.location.pathname.split("/")[2] + '/show'} className="sound__item-back-btn">뒤로가기</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(ShowSound))