import React, { Component } from 'react'
import client from '../../modules/client'
import { connect } from 'react-redux'

import loadingIcon from '../../assets/images/Ripple-1s-200px.svg'

class Premieres extends Component {
  state = {
    loaded: false,
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    previousDisabled: false,
    nextDisabled: true,
    premieres: [],
    totalAmount: 0
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/sound_premieres',
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      },
      params: {
        year: this.state.year,
        month: this.state.month
      }
    }).then(res => {
      const premieres = res.data.data;
      let totalAmount = 0
      premieres.forEach(item => {
        if (item.amount === 60000) {
          totalAmount += (item.is_writer + item.is_narrator + item.is_producer) * 20000
        } else {
          totalAmount += item.is_producer * 40000
        }
      })
      this.setState({
        loaded: true,
        premieres,
        totalAmount
      })
    })
  }

  handlePreviousClick = e => {
    if (!this.state.previousDisabled) {
      if (this.state.month === 1) {
        const year = this.state.year - 1
        const month = 12
        if (year === 2020 && month === 2) {
          this.setState({
            previousDisabled: true,
            nextDisabled: false,
            year,
            month
          }, () => {
            this.handleSubmit()
          })
        } else {
          this.setState({
            nextDisabled: false,
            year,
            month
          }, () => {
            this.handleSubmit()
          })
        }
      } else {
        const month = this.state.month - 1
        if (this.state.year === 2020 && month === 2) {
          this.setState({
            previousDisabled: true,
            nextDisabled: false,
            month
          }, () => {
            this.handleSubmit()
          })
        } else {
          this.setState({
            nextDisabled: false,
            month
          }, () => {
            this.handleSubmit()
          })
        }
      }
    }
  }

  handleNextClick = e => {
    if (!this.state.nextDisabled) {
      const now = new Date();
      if (this.state.month === 12) {
        const year = this.state.year + 1
        const month = 1
        if (year === now.getFullYear() && month === now.getMonth() + 1) {
          this.setState({
            nextDisabled: true,
            previousDisabled: false,
            year,
            month
          }, () => {
            this.handleSubmit()
          })
        } else {
          this.setState({
            previousDisabled: false,
            year,
            month
          }, () => {
            this.handleSubmit()
          })
        }
      } else {
        const month = this.state.month + 1
        if (this.state.year === now.getFullYear() && month === now.getMonth() + 1) {
          this.setState({
            nextDisabled: true,
            previousDisabled: false,
            month
          }, () => {
            this.handleSubmit()
          })
        } else {
          this.setState({
            previousDisabled: false,
            month
          }, () => {
            this.handleSubmit()
          })
        }
      }
    }
  }

  handleSubmit = () => {
    this.setState({
      loaded: false
    }, () => {
      client({
        method: 'get',
        url: '/sound_premieres',
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        },
        params: {
          year: this.state.year,
          month: this.state.month
        }
      }).then(res => {
        const premieres = res.data.data;
        this.setState({
          loaded: true,
          premieres
        })
      })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }

    const divPreviousClass = this.state.previousDisabled ? "previous disabled" : "previous"
    const divNextClass = this.state.nextDisabled ? "next disabled" : "next"
    const premieresTableContent = this.state.premieres.length > 0 ? this.state.premieres.map(item => {
      const isWriter = item.is_writer ? <div className="writer role-content">작가</div> : null
      const isNarrator = item.is_narrator ? <div className="narrator role-content">내레이터</div> : null
      const isProducer = item.is_producer ? <div className="producer role-content">프로듀서</div> : null
      const premieredAt = item.premiered_at.split('T')[0]
      const paidDiv = item.paid ? <div className="finished">지급 완료</div> : <div className="pending">지급 대기</div>
      let amount = 0
      if (item.amount === 60000) {
        amount += item.is_writer * 20000 + item.is_narrator * 20000 + item.is_producer * 20000
      } else {
        amount += item.is_producer * 40000
      }
      return (
        <li key={item.id} className="premieres__table-item">
          <div className="premiered-at">{premieredAt}</div>
          <div className="name">{item.name}</div>
          <div className="role">
            {isWriter}
            {isNarrator}
            {isProducer}
          </div>
          <div className="amount">{amount}원</div>
          <div className="paid">{paidDiv}</div>
        </li>
      )
    }) : (<div className="premieres__table-item no-item">
      해당 월에 등록된 오디오 콘텐츠가 없습니다.
    </div>)
    return (
      <div className="premieres-wrapper">
        <h1>미니멈 개런티</h1>
        <div className="premieres__row">
          <div className="premieres__month-picker-wrapper">
            <div className={divPreviousClass} onClick={this.handlePreviousClick}>이전 달</div>
            <div className="year-and-month">
              <div className="year">{this.state.year + "년"}</div>
              <div className="month">{this.state.month + "월"}</div>
            </div>
            <div className={divNextClass} onClick={this.handleNextClick}>다음 달</div>
          </div>
          <div className="premieres__total-amount">
            <div className="total-amount-label">당월 총 수익: </div>
            <div className="total-amount">{this.state.totalAmount}원</div>
          </div>
        </div>
        <div className="premieres__table">
          <div className="premieres__table-legend">
            <div className="premiered-at legend">최초공개일</div>
            <div className="name legend">오디오 이름</div>
            <div className="role legend">역할</div>
            <div className="amount legend">금액</div>
            <div className="paid legend">지급여부</div>
          </div>
          <ul className="premieres__table-content">
            {premieresTableContent}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(Premieres)