import React, { Component } from 'react'
import { connect } from 'react-redux'
import client from '../../modules/client'

import PasswordChangePopup from './PasswordChangePopup'

import { YEARS, MONTHS, DAYS } from '../../helpers'

class MyInfo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      nickname: '',
      updatedNickname: '',
      gender: '',
      email: '',
      birthDate: '',
      updatedBirthYear: '',
      updatedBirthMonth: '',
      updatedBirthDay: '',
      description: '',
      updatedDescription: '',
      profileImage: '',
      profileImagePreviewUrl: '',
      isBirthDateChanging: false,
      isNicknameChanging: false,
      isDescriptionChanging: false,
      showPasswordChangePopup: false
    }
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/me',
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      const data = res.data.data;
      const birthDate = new Date(data.birth_date * 1000);
      const birthYear = birthDate.getFullYear();
      const birthMonth = birthDate.getMonth();
      const birthDay = birthDate.getDate();
      const birthDateStr = birthYear + "년 " + (birthMonth + 1) + "월 " + birthDay + "일"
      this.setState({
        name: data.name,
        nickname: data.nickname,
        updatedNickname: data.nickname,
        gender: data.gender,
        description: data.description,
        updatedDescription: data.description,
        email: data.email,
        birthDate: birthDateStr,
        updatedBirthYear: birthYear.toString(),
        updatedBirthMonth: (birthMonth + 1).toString(),
        updatedBirthDay: birthDay.toString(),
        profileImagePreviewUrl: data.profile_image
      })
    })
  }

  showPasswordChangePopup = () => {
    this.setState({
      showPasswordChangePopup: true
    })
  }

  handleChangedPassword = () => {
    alert("비밀번호가 성공적으로 변경되었습니다.")
    this.setState({
      showPasswordChangePopup: false
    })
  }

  toggleBirthDateChange = () => {
    this.setState({
      isBirthDateChanging: !this.state.isBirthDateChanging
    })
  }

  handleBirthYearChange = e => {
    this.setState({
      updatedBirthYear: e.target.value
    })
  }
  handleBirthMonthChange = e => {
    this.setState({
      updatedBirthMonth: e.target.value
    })
  }
  handleBirthDayChange = e => {
    this.setState({
      updatedBirthDay: e.target.value
    })
  }

  submitBirthDate = e => {
    const birth_date = new Date(this.state.updatedBirthYear + '-' + this.state.updatedBirthMonth + '-' + this.state.updatedBirthDay)
    client({
      method: 'patch',
      url: "/me/birth_date",
      headers: { 'Authorization': 'Bearer ' + this.props.user.token },
      data: {
        birth_date: birth_date
      }
    }).then(res => {
      const data = res.data.data;
      const birthDate = new Date(data.birth_date * 1000);
      const birthYear = birthDate.getFullYear();
      const birthMonth = birthDate.getMonth();
      const birthDay = birthDate.getDate();
      const birthDateStr = birthYear + "년 " + (birthMonth + 1) + "월 " + birthDay + "일"
      this.setState({
        birthDate: birthDateStr,
        isBirthDateChanging: false
      }, () => {
        alert("생년월일이 성공적으로 변경되었습니다.")
      })
    }).catch(err => {
      alert("서버와 통신에 문제가 생겼습니다: ", err)
    })
  }

  toggleNicknameChange = () => {
    this.setState({
      isNicknameChanging: !this.state.isNicknameChanging
    })
  }

  updateNickname = e => {
    this.setState({
      updatedNickname: e.target.value
    })
  }

  submitNickname = e => {
    client({
      method: 'patch',
      url: "/me/nickname",
      headers: { 'Authorization': 'Bearer ' + this.props.user.token },
      data: {
        nickname: this.state.updatedNickname
      }
    }).then(res => {
      this.setState({
        nickname: res.data.data.nickname,
        isNicknameChanging: false
      }, () => {
        alert("닉네임이 성공적으로 변경되었습니다.")
      })
    }).catch(err => {
      alert("서버와 통신에 문제가 생겼습니다: ", err)
    })
  }

  toggleDescriptionChange = e => {
    this.setState({
      isDescriptionChanging: !this.state.isDescriptionChanging
    })
  }

  updateDescription = e => {
    this.setState({
      updatedDescription: e.target.value
    })
  }

  submitDescription = e => {
    client({
      method: 'patch',
      url: "/me/description",
      headers: { 'Authorization': 'Bearer ' + this.props.user.token },
      data: {
        description: this.state.updatedDescription
      }
    }).then(res => {
      this.setState({
        description: res.data.data.description,
        isDescriptionChanging: false
      }, () => {
        alert("한마디가 성공적으로 변경되었습니다.")
      })
    }).catch(err => {
      alert("서버와 통신에 문제가 생겼습니다: ", err)
    })
  }

  closePopup = e => {
    this.setState({
      showAuthenticationPopup: false,
      showPasswordChangePopup: false
    })
  }

  handleProfileImageFileChange = e => {
    e.preventDefault();

    let file = e.target.files[0];

    this.setState({
      profileImageFile: file,
      profileImagePreviewUrl: URL.createObjectURL(file)
    })
  }

  submitProfileImage = e => {
    e.preventDefault();
    let form_data = new FormData();
    form_data.append('profile_image', this.state.profileImageFile, this.state.profileImageFile.name)

    client({
      method: 'patch',
      url: "/me/profile_image",
      data: form_data,
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token,
        'content-type': 'multipart/form-data'
      }
    }).then(res => {
      alert("프로필 사진이 성공적으로 변경되었습니다!")
      this.setState({
        profileImageFile: '',
        profileImagePreviewUrl: res.data.data.profile_image
      })
    })
  }

  render() {
    const gender = this.state.gender === "male" ? "남" : "여";
    const popupOpacityClass = this.state.showAuthenticationPopup || this.state.showPasswordChangePopup ? "active" : "hidden"
    const nicknameSpanClass = this.state.isNicknameChanging ? "hidden" : "active value"
    const nicknameInputClass = this.state.isNicknameChanging ? "active" : "hidden"
    const descriptionDivClass = this.state.isDescriptionChanging ? "hidden" : "active value description"
    const descriptionSpanClass = this.state.isDescriptionChanging ? "hidden" : "active value"
    const descriptionInputClass = this.state.isDescriptionChanging ? "active" : "hidden"
    const previewStyle = this.state.profileImagePreviewUrl ? {
      backgroundImage: "url(" + this.state.profileImagePreviewUrl + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    } : {
        backgroundColor: "#f7f7f7"
      };
    const profileBtnClass = this.state.profileImageFile ? "myinfo__btn upload-image" : "hidden"

    const passwordChangePopup = this.state.showPasswordChangePopup ? <PasswordChangePopup handleChangedPassword={this.handleChangedPassword} closePopup={this.closePopup} /> : '';

    const birthYearOptions = YEARS.map(year => <option key={year} value={year}>{year}</option>)
    const birthMonthOptions = MONTHS.map(month => <option key={month} value={month}>{month}</option>)
    const birthDayOptions = DAYS.map(day => <option key={day} value={day}>{day}</option>)
    const updateBirthDateDiv = this.state.isBirthDateChanging ? (
      <div className="myinfo__item-birth-date-update-wrapper">
        <select name="birthYear" id="birthYear" defaultValue={this.state.updatedBirthYear} onChange={this.handleBirthYearChange}>
          {birthYearOptions}
        </select>
        <select name="birthMonth" id="birthMonth" defaultValue={this.state.updatedBirthMonth} onChange={this.handleBirthMonthChange}>
          {birthMonthOptions}
        </select>
        <select name="birthYear" id="birthYear" defaultValue={this.state.updatedBirthDay} onChange={this.handleBirthDayChange}>
          {birthDayOptions}
        </select>
        <button className="myinfo__btn cancel" onClick={this.toggleBirthDateChange}>취소</button>
        <button className="myinfo__btn save" onClick={this.submitBirthDate}>저장</button>
      </div>
    ) : (
        <div className="myinfo__item-birth-date-update-wrapper">
          <span className="value">{this.state.birthDate}</span>
          <button className="myinfo__btn change" onClick={this.toggleBirthDateChange}>변경</button>
        </div>
      )

    return (
      <div className="myinfo">
        <div className={popupOpacityClass + " popup-open"}></div>
        <div className="myinfo__info-wrapper">
          <div className="myinfo__item">
            <div>
              <span className="title">이름</span>
              <span className="value">{this.state.name}</span>
            </div>
          </div>
          <div className="myinfo__item">
            <div>
              <span className="title">이메일</span>
              <span className="value">{this.state.email}</span>
            </div>
          </div>
          <div className="myinfo__item">
            <div>
              <span className="title">성별</span>
              <span className="value">{gender}</span>
            </div>
          </div>
          <div className="myinfo__item">
            <div>
              <span className="title">생년월일</span>
              {updateBirthDateDiv}
            </div>
          </div>
          <div className="myinfo__item">
            <div>
              <span className="title">닉네임</span>
              <span className={nicknameSpanClass}>{this.state.nickname}</span>
              <input className={nicknameInputClass} type="text" value={this.state.updatedNickname} onChange={this.updateNickname} />
            </div>
            <div className={nicknameInputClass + " myinfo__item-btns"}>
              <button className="myinfo__btn cancel" onClick={this.toggleNicknameChange}>취소</button>
              <button className="myinfo__btn save" onClick={this.submitNickname}>저장</button>
            </div>
            <div className={nicknameSpanClass + " myinfo__item-btns"}>
              <button className="myinfo__btn change" onClick={this.toggleNicknameChange}>변경</button>
            </div>
          </div>
          <div className="myinfo__item description-wrapper">
            <div>
              <span className="title">한마디 자기소개</span>
              <div className={descriptionDivClass}>{this.state.description}</div>
              <textarea className={descriptionInputClass} value={this.state.updatedDescription} onChange={this.updateDescription} />
            </div>
            <div className={descriptionInputClass + " myinfo__item-btns"}>
              <button className="myinfo__btn cancel" onClick={this.toggleDescriptionChange}>취소</button>
              <button className="myinfo__btn save" onClick={this.submitDescription}>저장</button>
            </div>
            <div className={descriptionSpanClass + " myinfo__item-btns"}>
              <button className="myinfo__btn change" onClick={this.toggleDescriptionChange}>변경</button>
            </div>
          </div>
          <div className="myinfo__profile-image-wrapper">
            <div className="myinfo__profile-image-preview" style={previewStyle}></div>
            <input type="file" accept="image/*" onChange={this.handleProfileImageFileChange} />
            <button className={profileBtnClass} onClick={this.submitProfileImage}>사진 변경</button>
          </div>
        </div>
        <div className="myinfo__password-change">
          <button className="myinfo__password-change-btn" onClick={this.showPasswordChangePopup}>비밀번호 변경</button>
        </div>
        {passwordChangePopup}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(MyInfo)