import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import client from '../modules/client'
import { removeUser } from '../store/actions/userActions'
import WebGuide from '../assets/documents/lucidist_web_usage_guide_v0_2.pdf'
import loadingIcon from '../assets/images/Ripple-1s-200px.svg'

class Sidebar extends Component {
  state = {
    loaded: false,
    showMinimumGuarantee: false
  }

  logout = () => {
    localStorage.removeItem("creatorToken");
    this.props.removeUser()
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/me',
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      const me = res.data.data;
      if (me.is_receiving_minimum_guarantee) {
        this.setState({
          loaded: true,
          showMinimumGuarantee: true
        })
      } else {
        this.setState({
          loaded: true
        })
      }

    }).catch(err => {
      console.error(err)
    })
  }

  render() {
    if (!this.state.loaded) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }

    const liChannelClass = this.props.location.pathname.includes("channels") ? "active" : ''
    const liInfoClass = this.props.location.pathname.includes("mypage/info") ? "active" : ''
    const liAnalyticsClass = this.props.location.pathname.includes("mypage/analytics") ? "active" : ''
    const liPremieresClass = this.props.location.pathname.includes("mypage/premieres") ? "active" : ''
    const liRevenueShareClass = this.props.location.pathname.includes("mypage/revenue_share") ? "active" : ''
    const liPaymentHistoryClass = this.props.location.pathname.includes("mypage/payment_histories") ? "active" : ''

    const liPremieres = this.state.showMinimumGuarantee ? (<li className={liPremieresClass}>
      <Link to="/mypage/premieres">미니멈 개런티</Link>
    </li>) : null
    return (
      <div className="sidebar">
        <ul className="sidebar__links">
          <li>
            <a href="https://www.notion.so/2easylabs/dafb7553b00b4436975e038a1d87485f" target="_blank" rel="noopener noreferrer">루시드 공지사항 <i className="material-icons">launch</i></a>
          </li>
          <li className={liChannelClass}>
            <Link to="/channels">채널 관리</Link>
          </li>
          <li className={liInfoClass}>
            <Link to="/mypage/info">내 정보</Link>
          </li>
          <li className={liAnalyticsClass}>
            <Link to="/mypage/analytics">채널 통계</Link>
          </li>
          {liPremieres}
          <li className={liRevenueShareClass}>
            <Link to="/mypage/revenue_share">구독 수익</Link>
          </li>
          <li className={liPaymentHistoryClass}>
            <Link to="/mypage/payment_histories">출금 내역</Link>
          </li>
          <li>
            <a href={WebGuide} target="_blank" rel="noopener noreferrer">웹 사용 가이드 <i className="material-icons">launch</i></a>
          </li>
          <li>
            <a href="https://www.notion.so/2easylabs/0170a371575241e484f7a96ceea768cb" target="_blank" rel="noopener noreferrer">콘텐츠 가이드 <i className="material-icons">launch</i></a>
          </li>
          <li>
            <button onClick={this.logout}>로그아웃</button>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeUser: () => dispatch(removeUser())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))