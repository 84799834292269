import React, { Component } from 'react'
import { connect } from 'react-redux'
import client from '../../modules/client';

class Analytics extends Component {
  state = {
    totalSessions: 0,
    channelSessions: [],
    monthSessions: 0,
    currentMonthChannelSessions: []
  }

  componentDidMount() {
    client({
      method: 'get',
      url: '/analytics',
      headers: {
        'Authorization': 'Bearer ' + this.props.user.token
      }
    }).then(res => {
      const data = res.data.data;
      this.setState({
        totalSessions: data.acc_total_sessions,
        channelSessions: data.acc_channel_sessions,
        monthSessions: data.current_sessions,
        currentMonthChannelSessions: data.current_channel_sessions
      })
    })
  }

  render() {
    const accChannelItems = this.state.channelSessions.map(item => {
      return (
        <li key={item.id} className="analytics__list-item">
          <div className="analytics__list-item-title">{item.name}</div>
          <div className="analytics__list-item-content">{item.sessions}</div>
        </li>
      )
    })
    const monthSessionItems = this.state.currentMonthChannelSessions.map(item => {
      return (
        <li key={item.id} className="analytics__list-item">
          <div className="analytics__list-item-title">{item.name}</div>
          <div className="analytics__list-item-content">{item.sessions}</div>
        </li>
      )
    })
    return (
      <div className="analytics container">
        <h1>내 채널 통계</h1>
        <div className="analytics__row">
          <div className="title"><span role="img" aria-label="icon">▶️</span> 누적 재생 횟수: <span>{this.state.totalSessions}</span></div>
          <div className="content">
            <ul className="analytics__list">
              <li className="analytics__list-item legend">
                <div className="analytics__list-item-title">채널 이름</div>
                <div className="analytics__list-item-content">재생 횟수</div>
              </li>
              {accChannelItems}
            </ul>
          </div>
        </div>
        <div className="analytics__row">
          <div className="title"><span role="img" aria-label="icon">▶️</span> 월간 재생 횟수: <span>{this.state.monthSessions}</span></div>
          <div className="content">
            <ul className="analytics__list">
              <li className="analytics__list-item legend">
                <div className="analytics__list-item-title">채널 이름</div>
                <div className="analytics__list-item-content">재생 횟수</div>
              </li>
              {monthSessionItems}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default connect(mapStateToProps)(Analytics)