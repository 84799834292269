import { Link } from 'react-router-dom'
import React from 'react'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-wrapper">
        <div className="logo-wrapper">
          <Link to="/">Home</Link>
        </div>
        <ul className="navbar__links">
          <li>
            <a href="https://www.notion.so/2easylabs/0170a371575241e484f7a96ceea768cb" target="_blank" rel="noopener noreferrer">소개</a>
          </li>
          <li>
            <a href="https://forms.gle/EPsmwTmvN4h2bkCG8" target="_blank" rel="noopener noreferrer">크리에이터 지원</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar;