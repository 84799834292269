import React, { Component } from 'react'
import client from '../../modules/client'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import loadingIcon from '../../assets/images/Ripple-1s-200px.svg'
import PaymentRequest from '../../components/mypage/PaymentRequest'
import RevenueShareDetails from '../../components/mypage/RevenueShareDetails';

class RevenueShare extends Component {
  state = {
    loaded: false,
    paymentRequestEnabled: false,
    showPaymentRequestPopup: false,
    confirmedIncome: 0,
    expectedIncome: 0,
    revenueShareHistories: [],
    showRsDetailsPopup: false,
    year: new Date().getFullYear().toString(),
    month: (new Date().getMonth() + 1).toString()
  }

  async componentDidMount() {
    try {
      const revenueShareRes = await client({
        method: 'get',
        url: '/revenue_share',
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })
      const revenueShareData = revenueShareRes.data.data

      const confirmedIncomeRes = await client({
        method: 'get',
        url: '/confirmed_income',
        headers: {
          'Authorization': 'Bearer ' + this.props.user.token
        }
      })

      const confirmedIncomeData = confirmedIncomeRes.data.data

      if (confirmedIncomeData.confirmed_income > 100000) {
        this.setState({
          loaded: true,
          paymentRequestEnabled: true,
          confirmedIncome: Math.floor(confirmedIncomeData.confirmed_income),
          expectedIncome: Math.floor(revenueShareData.expected_income),
          revenueShareHistories: revenueShareData.revenue_share_histories
        })
      } else {
        this.setState({
          loaded: true,
          confirmedIncome: Math.floor(confirmedIncomeData.confirmed_income),
          expectedIncome: Math.floor(revenueShareData.expected_income),
          revenueShareHistories: revenueShareData.revenue_share_histories
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleCheckoutBtnClick = e => {
    this.setState({ showPaymentRequestPopup: true })
  }

  closePopup = e => {
    this.setState({ showPaymentRequestPopup: false })
  }

  handleShowCurrentRsDetailsBtnClick = e => {
    this.setState({
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      showRsDetailsPopup: true
    })
  }

  handleShowPastRsDetailsBtnClick = (year, month, e) => {
    this.setState({
      year,
      month,
      showRsDetailsPopup: true
    })
  }

  closeRsPopup = e => {
    this.setState({ showRsDetailsPopup: false })
  }

  goToPaymentHistory = e => {
    this.props.history.push('/mypage/payment_histories')
  }

  render() {
    if (!this.state.loaded) {
      return <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div>
    }
    const rsList = this.state.revenueShareHistories.length > 0 ? this.state.revenueShareHistories.map(item => {
      const key = item.year * 100 + item.month
      const amount = Math.floor(item.amount)
      return (
        <li key={key} className="rs-table-item">
          <div className="year-month">{item.year}년 {item.month}월</div>
          <div className="amount">{amount}원</div>
          <div className="details" onClick={e => this.handleShowPastRsDetailsBtnClick(item.year, item.month, e)}>상세내역보기</div>
        </li>
      )
    }) : (<li className="rs-table-item no-item">
      <div className="no-content">내역이 없습니다.</div>
    </li>)

    const paymentRequestBtn = this.state.paymentRequestEnabled ? <div className="checkout-btn" onClick={this.handleCheckoutBtnClick}>출금 신청</div> : <div className="checkout-btn disabled">출금 신청</div>

    const paymentRequestPopup = this.state.showPaymentRequestPopup ? <PaymentRequest confirmedIncome={this.state.confirmedIncome} closePopup={this.closePopup} goToPaymentHistory={this.goToPaymentHistory} /> : null;

    const revenueShareDetailsPopup = this.state.showRsDetailsPopup ? <RevenueShareDetails year={this.state.year} month={this.state.month} closeRsPopup={this.closeRsPopup} /> : null;

    return (
      <div className="revenue-share-wrapper">
        <h1>구독 수익</h1>
        <div className="revenue-share__row">
          <div className="confirmed-income">
            <div className="title">총 확정 수익</div>
            {paymentRequestBtn}
          </div>
          <div className="confirmed-amount">{this.state.confirmedIncome}원</div>
        </div>
        <div className="revenue-share__row">
          <div className="expected-income">이번 달 예상 수입
            <div className="expected-details" onClick={this.handleShowCurrentRsDetailsBtnClick}>상세내역보기</div>
          </div>
          <div className="expected-amount">{this.state.expectedIncome}원</div>
          <div className="revenue-share-history">
            <div className="revenue-share-history-title">월별 내역</div>
            <div className="rs-table">
              <div className="rs-table-legend">
                <div className="year-month legend">연월</div>
                <div className="amount legend">금액</div>
                <div className="details legend"></div>
              </div>
              <ul className="rs-table-content">
                {rsList}
              </ul>
            </div>
          </div>
        </div>
        {paymentRequestPopup}
        {revenueShareDetailsPopup}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(RevenueShare))