import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'

class SoundList extends Component {
  render() {
    const soundItems = this.props.sounds.length === 0 ? (
      <div className="no-sounds">아직 등록된 오디오 콘텐츠가 없습니다.</div>
    ) : (
        this.props.sounds.map(soundItem => {
          const soundImagePreview = soundItem.imagePreviewUrl ? (<img src={soundItem.imagePreviewUrl} className="sound-preview-img" alt="preview" />) : (<div className="sound-preview-text">곡 커버 이미지가 없습니다.</div>)
          return (
            <div key={soundItem.id} className="sound__item-wrapper">
              <div className="sound__items-name">
                <Link to={"/channels/" + this.props.channelId + "/sounds/" + soundItem.id + "/show"} id={"sound-name_" + soundItem.id}>{soundItem.name}</Link>
              </div>
              <div className="sound__items-image">
                <div className="sound__image-preview">
                  {soundImagePreview}
                </div>
              </div>
              <div className="sound__items-audio">
                <audio id={"sound-preview_" + soundItem.id} src={soundItem.soundFilePreviewUrl} controls />
              </div>
              <div className="sound__items-priority">
                <div id={"sound-priority_" + soundItem.id}>{soundItem.priority}</div>
              </div>
              <div className="sound__items-is_premium">
                <input type="checkbox" id={"sound-is-premium_" + soundItem.id} checked={soundItem.isPremium} disabled />
              </div>
              <div className="sound__items-is_narrated">
                <input type="checkbox" id={"sound-is-music-only_" + soundItem.id} checked={soundItem.isNarrationIncluded} disabled />
              </div>
            </div>
          )
        })
      )

    return (
      <div className="sound__items-wrapper">
        <ul className="sound__items-legend">
          <li className="sound__items-name">제목</li>
          <li className="sound__items-image">이미지</li>
          <li className="sound__items-audio">곡</li>
          <li className="sound__items-priority">트랙 순서</li>
          <li className="sound__items-is_premium">유료 여부</li>
          <li className="sound__items-is_narrated">내레이션 여부</li>
        </ul>
        {soundItems}
      </div>
    )
  }
}

export default withRouter(SoundList)