import React, { Component } from 'react'
import Frame from './components/Frame';
import client from './modules/client';
import { connect } from 'react-redux'

import { setUser } from './store/actions/userActions'
import loadingIcon from './assets/images/Ripple-1s-200px.svg'

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    }
  }
  componentDidMount() {
    const token = localStorage.getItem("creatorToken")
    if (token !== null) {
      client({
        method: 'post',
        url: '/token_login',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        if (res.status === 200) {
          localStorage.setItem(
            "creatorToken",
            res.data.data.token
          );
          this.props.setUser({ token: res.data.data.token, isSuperUser: res.data.data.is_super_user })
          this.setState({ isLoading: false })
        }
      }).catch(err => {
        localStorage.removeItem('creatorToken');
        this.setState({ isLoading: false })
      })
    } else {
      this.setState({ isLoading: false })
    }
  }
  render() {
    const frame = this.state.isLoading ? <div className="loading-fullpage"><img src={loadingIcon} alt="loading..." /></div> : <Frame />
    return (
      frame
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(setUser(user))
  }
}

export default connect(null, mapDispatchToProps)(App);
